// React Imports
import { FC, useEffect, useState } from "react";

// UI Imports
import { Box, Modal } from "@mui/material";

// Functional Imports
import API from "../../api/API";

// Component Imports
import Text from "../Text";
import Loader from "../Loader";

interface ResumeTxtPopupProps {
  open: boolean;
  setOpen: any;
  documentLink?: any;
  documentName?: string;

  isTesting?: boolean;
}

const ResumeTxtPopup: FC<ResumeTxtPopupProps> = (props) => {
  const [txtContent, setTxtContent] = useState("");
  const [dataLoading, setDataLoading] = useState(false);

  useEffect(() => {
    if (props.isTesting) {
      handleClose();
    }
  }, []);

  useEffect(() => {
    setDataLoading(true);
    API.genericGetRequest(props.documentLink)
      .then((response) => {
        setDataLoading(false);
        setTxtContent(response);
      })
      .catch((e) => {
        setDataLoading(false);
      });
  }, [props.documentLink]);

  const handleClose = () => {
    props.setOpen(false);
  };

  return (
    <Modal
      open={props.open}
      onClose={handleClose}
      sx={{
        border: "none",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        data-testid="resume-txt-popup"
        sx={{
          border: "none",
          width: "60vw",
          height: "85vh",
          backgroundColor: "white",
          overflowY: "scroll",
          p: 4,
        }}
      >
        {!dataLoading && (
          <Text
            style={{
              whiteSpace: "pre-line",
            }}
          >
            {txtContent}
          </Text>
        )}
        {dataLoading && <Loader />}
      </Box>
    </Modal>
  );
};

export default ResumeTxtPopup;
