// React Imports
import { FC, useEffect } from "react";

// UI Imports
import { Box, IconButton } from "@mui/material";
import { RxHamburgerMenu } from "react-icons/rx";
import { IoGridOutline, IoDocumentTextOutline, IoMenu } from "react-icons/io5";
import { BsBriefcase } from "react-icons/bs";
import { TiFolderDelete } from "react-icons/ti";
import { FaArrowLeftLong } from "react-icons/fa6";
import { LuUser, LuUserCog } from "react-icons/lu";

// Functional Imports
import { useLocation, useNavigate } from "react-router-dom";
import Paths from "../../routes/Paths";
import Colors from "../../utils/Colors";

// Component Imports
import SideBarTile from "./SideBarTile";
import Functions from "../../utils/Functions";

interface SideBarProps {
  isdashboard?: any;
  setIsdashboard?: any;
  menuexpand?: any;
  setMenuexpand?: any;

  isTesting?: boolean;
}

const SideBar: FC<SideBarProps> = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (props.isTesting) {
      openSideBar();
      toggleSidebar();
      handleHomeClick();
      handleJobsClick();
      handleApplicationsClick();
      handleUsersClick();
      handleProjectClick();
      handleWorkerClick();
    }
  }, []);

  const openSideBar = () => {
    props.setIsdashboard(false);
    props.setMenuexpand(true);
  };

  const toggleSidebar = () => {
    props.setMenuexpand(!props.menuexpand);
    props.setIsdashboard(!props.isdashboard);
  };

  const handleHomeClick = () => {
    navigate(Paths.DASHBOARD);
    props.setMenuexpand(false);
  };

  const handleJobsClick = () => {
    navigate(Paths.JOBS);
    props.setMenuexpand(false);
  };

  const handleApplicationsClick = () => {
    navigate(Paths.APPLICATIONS);
    props.setMenuexpand(false);
  };

  const handleUsersClick = () => {
    navigate(Paths.USERS);
    props.setMenuexpand(false);
  };

  const handleProjectClick = () => {
    navigate(Paths.PROJECTS);
    props.setMenuexpand(false);
  };

  const handleWorkerClick = () => {
    navigate(Paths.WORKERS);
    props.setMenuexpand(false);
  };

  return (
    <>
      {location.pathname.includes(Paths.DASHBOARD) && !props.menuexpand && (
        <IconButton
          data-testid="sidebar-comp"
          sx={{
            ml: 0,
            mr: 5,
            position: "absolute",
            zIndex: 999,
            left: "1.5%",
            top: "2.5%",
          }}
          onClick={openSideBar}
        >
          <IoMenu />
        </IconButton>
      )}
      <Box
        className="showexpandnav test"
        data-testid="sidebar-comp"
        sx={{
          marginLeft: !props.menuexpand ? "-250px" : "0",
          backgroundColor: Colors.Blue7,
          maxWidth: 250,
          height: "100%",
          textAlign: "left",
          transition: "all 0.3s ease-in-out",
        }}
      >
        <SideBarTile
          label=""
          icon={<FaArrowLeftLong size={22} color={Colors.White} />}
          sx={{
            display: "flex",
            justifyContent: "left",
            alignItems: "center",
            height: "10vh",
            borderBottom: "0.2px solid #fff",
            pr: 20,
          }}
          onClick={toggleSidebar}
          tileclass="navitem"
          menuexpand={props.menuexpand}
        />
        <SideBarTile
          label="Home"
          icon={<IoGridOutline size={22} color={Colors.White} />}
          menulabel="Home"
          onClick={handleHomeClick}
          isActive={location.pathname.includes(Paths.DASHBOARD)}
          sx={{
            display: "flex",
            justifyContent: "left",
            alignItems: "center",
          }}
          tileclass="navitem"
          menuexpand={props.menuexpand}
        />
        <SideBarTile
          label="Jobs"
          icon={<BsBriefcase size={22} color={Colors.White} />}
          onClick={handleJobsClick}
          menulabel="Jobs"
          tileclass="navitem"
          isActive={
            location.pathname.includes(Paths.JOBS) ||
            location.pathname.includes(Paths.EDIT_JOB) ||
            location.pathname.includes(Paths.VIEW_JOB)
          }
          sx={{
            display: "flex",
            justifyContent: "left",
            alignItems: "center",
            pr: 2,
          }}
          menuexpand={props.menuexpand}
        />
        <SideBarTile
          isActive={location.pathname.includes(Paths.APPLICATIONS)}
          label="Application"
          icon={<IoDocumentTextOutline size={22} color={Colors.White} />}
          onClick={handleApplicationsClick}
          menulabel="Applications"
          tileclass="navitem"
          sx={{
            display: "flex",
            justifyContent: "left",
            alignItems: "center",
            pr: 2,
          }}
          menuexpand={props.menuexpand}
        />
        <SideBarTile
          isActive={
            location.pathname.includes(Paths.PROJECTS) ||
            location.pathname.includes(Paths.PROJECT)
          }
          label="Projects"
          onClick={handleProjectClick}
          icon={
            <TiFolderDelete
              style={{
                marginRight: -4,
              }}
              size={26}
              color={Colors.White}
            />
          }
          sx={{
            display: "flex",
            justifyContent: "left",
            alignItems: "center",
            pr: 2,
          }}
          menulabel="Projects"
          menuexpand={props.menuexpand}
          tileclass="navitem"
        />
        <SideBarTile
          isActive={
            location.pathname.includes(Paths.WORKERS) ||
            location.pathname.includes(Paths.WORKER)
          }
          label="Workers"
          onClick={handleWorkerClick}
          icon={<LuUser size={22} color={Colors.White} />}
          sx={{
            display: "flex",
            justifyContent: "left",
            alignItems: "center",
            pr: 2,
          }}
          menulabel="Workers"
          menuexpand={props.menuexpand}
          tileclass="navitem"
        />
        <SideBarTile
          isActive={
            location.pathname.includes(Paths.USERS) ||
            location.pathname.includes(Paths.USER) ||
            location.pathname.includes(Paths.USEREDIT)
          }
          label="Users"
          icon={<LuUserCog size={22} color={Colors.White} />}
          sx={{
            display: "flex",
            justifyContent: "left",
            alignItems: "center",
            pr: 2,
          }}
          onClick={handleUsersClick}
          menulabel="Users"
          menuexpand={props.menuexpand}
          tileclass="navitem"
        />
      </Box>
      {!location.pathname.includes(Paths.DASHBOARD) && !props.menuexpand && (
        <Box
          className={Functions.ValueRetrieve.checknav(props.menuexpand)}
          data-testid="sidebar-comp"
          sx={{
            backgroundColor: Colors.Blue7,
            maxWidth: 64,
            height: "100%",
            textAlign: "left",
            transition: "all 0.3s ease-in-out",
          }}
        >
          <SideBarTile
            label=""
            icon={
              !props.menuexpand ? (
                <RxHamburgerMenu size={22} color={Colors.White} />
              ) : (
                <FaArrowLeftLong size={22} color={Colors.White} />
              )
            }
            sx={{
              display: "flex",
              justifyContent: "left",
              alignItems: "center",
              height: "10vh",
              borderBottom: "0.2px solid #fff",
              pr: 20,
            }}
            onClick={toggleSidebar}
            tileclass="navitem"
            menuexpand={props.menuexpand}
          />
          <SideBarTile
            label="Home"
            icon={<IoGridOutline size={22} color={Colors.White} />}
            menulabel="Home"
            onClick={handleHomeClick}
            isActive={location.pathname.includes(Paths.DASHBOARD)}
            sx={{
              display: "flex",
              justifyContent: "left",
              alignItems: "center",
              pr: 2,
            }}
            tileclass="navitem"
            menuexpand={props.menuexpand}
          />
          <SideBarTile
            label="Jobs"
            icon={<BsBriefcase size={22} color={Colors.White} />}
            onClick={handleJobsClick}
            menulabel="Jobs"
            isActive={
              location.pathname.includes(Paths.JOBS) ||
              location.pathname.includes(Paths.EDIT_JOB) ||
              location.pathname.includes(Paths.VIEW_JOB)
            }
            sx={{
              display: "flex",
              justifyContent: "left",
              alignItems: "center",
              pr: 2,
            }}
            tileclass="navitem"
            menuexpand={props.menuexpand}
          />
          <SideBarTile
            isActive={location.pathname.includes(Paths.APPLICATIONS)}
            label="Application"
            icon={<IoDocumentTextOutline size={22} color={Colors.White} />}
            onClick={handleApplicationsClick}
            menulabel="Applications"
            sx={{
              display: "flex",
              justifyContent: "left",
              alignItems: "center",
              pr: 2,
            }}
            tileclass="navitem"
            menuexpand={props.menuexpand}
          />
          <SideBarTile
            isActive={
              location.pathname.includes(Paths.PROJECTS) ||
              location.pathname.includes(Paths.PROJECT)
            }
            label="Projects"
            onClick={handleProjectClick}
            icon={<TiFolderDelete size={26} color={Colors.White} />}
            sx={{
              display: "flex",
              justifyContent: "left",
              alignItems: "center",
              pr: 2,
            }}
            tileclass="navitem"
            menulabel="Projects"
            menuexpand={props.menuexpand}
          />
          <SideBarTile
            isActive={
              location.pathname.includes(Paths.WORKERS) ||
              location.pathname.includes(Paths.WORKER)
            }
            label="Workers"
            onClick={handleWorkerClick}
            icon={<LuUser size={22} color={Colors.White} />}
            sx={{
              display: "flex",
              justifyContent: "left",
              alignItems: "center",
              pr: 2,
            }}
            tileclass="navitem"
            menulabel="Workers"
            menuexpand={props.menuexpand}
          />
          <SideBarTile
            isActive={
              location.pathname.includes(Paths.USERS) ||
              location.pathname.includes(Paths.USER) ||
              location.pathname.includes(Paths.USEREDIT)
            }
            label="Users"
            icon={<LuUserCog size={22} color={Colors.White} />}
            onClick={handleUsersClick}
            menulabel="Users"
            sx={{
              display: "flex",
              justifyContent: "left",
              alignItems: "center",
              pr: 2,
            }}
            tileclass="navitem"
            menuexpand={props.menuexpand}
          />
        </Box>
      )}
    </>
  );
};

export default SideBar;
