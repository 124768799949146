// React Imports
import { FC, useEffect, useState } from "react";

// UI Imports
import { Box } from "@mui/material";
import Tab from "@mui/material/Tab";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";

// Functional Imports
import { useLocation, useNavigate } from "react-router-dom";
import Colors from "../../utils/Colors";

// Component Imports
import Detailtabsection from "./detailtabsection";
import InterviewDetail from "./InterviewDetail";
import DocumentsList from "../../common/DocumentsList";

interface TabsectionProps {
  appData?: any;
  skilldata?: any;
  submissionID?: any;
  candidateName?: any;
  candidateEmail?: any;
  initialSlots?: any;
  setAvailabilities?: any;
  slotId?: any;
  resumeVLNStatus?: any;
  updateDataAgain?: any;
  setUpdateDataAgain?: any;
  modifyMode?: any;
  description?: any;

  isTesting?: boolean;
}

const Tabsection: FC<TabsectionProps> = (props) => {
  const [value, setValue] = useState("1");
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (props.isTesting) {
      handleChange({} as React.SyntheticEvent, "2");
      handleChange({} as React.SyntheticEvent, "3");
      handleChange({} as React.SyntheticEvent, "4");
    }
  }, []);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);

    let tab;
    if (newValue === "2") {
      tab = "interviews";
    } else if (newValue === "3") {
      tab = "documents";
    } else {
      tab = "application";
    }

    const newUrl = `${location.pathname}?tab=${tab}`;
    navigate(newUrl, { replace: true });
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const tab = params.get("tab");
    if (tab === "interviews") {
      setValue("2");
    } else if (tab === "documents") {
      setValue("3");
    } else {
      setValue("1");
    }
  }, [location.search]);

  return (
    <Box data-testid='tabsection-application-details' sx={{ width: "100%", mt: 2 }} className="editdetailtab">
      <TabContext value={value}>
        <Box>
          <TabList onChange={handleChange}>
            <Tab
              sx={{
                backgroundColor: Colors.LightBlue7,
              }}
              label="Application Details"
              value="1"
            />
            {props.appData?.resumeVLNStatus !== "Pending Review" && (
              <Tab
                sx={{
                  backgroundColor: Colors.LightBlue7,
                }}
                label="Interviews"
                value="2"
              />
            )}
            <Tab
              sx={{
                backgroundColor: Colors.LightBlue7,
              }}
              label="Documents"
              value="3"
            />
          </TabList>
        </Box>

        <TabPanel sx={{ background: "#fff", mr: 2 }} value="1">
          <Detailtabsection appData={props.appData} />
        </TabPanel>
        {props.appData?.resumeVLNStatus !== "Pending Review" && (
          <TabPanel sx={{ background: "#fff", mr: 2 }} value="2">
            <InterviewDetail
              interviewData={props.appData}
              interviewSubmissionID={props.submissionID}
              candidateName={props.candidateName}
              candidateEmail={props.candidateEmail}
              initialSlots={props.initialSlots}
              setAvailabilities={props.setAvailabilities}
              slotId={props.slotId}
              resumeVLNStatus={props.resumeVLNStatus}
              updateDataAgain={props.updateDataAgain}
              setUpdateDataAgain={props.setUpdateDataAgain}
              modifyMode={props.modifyMode}
              description={props.description}
            />
          </TabPanel>
        )}
        <TabPanel sx={{ background: "#fff", mr: 2 }} value="3">
          <DocumentsList tableData={props.appData?.documentDetails} />
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export default Tabsection;
