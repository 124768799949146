// React Imports
import { FC } from "react";

// UI Imports
import {
  DataGrid,
  GridColDef,
  GridColumnHeaderParams,
  GridRenderCellParams,
} from "@mui/x-data-grid";

// Functional Imports
import Functions from "../../utils/Functions";
import Colors from "../../utils/Colors";

// Component Imports
import Text from "../../common/Text";

interface TimesheetListProps {
  data?: any;
  isExpanded?: boolean;
}

const TimesheetList: FC<TimesheetListProps> = (props) => {
  return (
    <DataGrid
      sx={{
        width: "100%",
        display: props.isExpanded ? "block" : "none",
      }}
      columns={columns}
      rows={props.data}
      hideFooter
      getRowId={(row) => row.entryDate}
      rowSelection={false}
    />
  );
};

export default TimesheetList;

const headerText = {
  renderHeader: (params: GridColumnHeaderParams) => {
    return (
      <Text fontSize={16} fontWeight={700}>
        {params.colDef?.headerName}
      </Text>
    );
  },
};

const cellRender = {
  renderCell: (params: GridRenderCellParams) => {
    return <Text>{params.row?.[params.field]}</Text>;
  },
};

const commonColumnProps = {
  disableColumnMenu: true,
  sortable: false,
  flex: 1,
  minWidth: 180,
};

const columns: GridColDef[] = [
  {
    field: "entryDate",
    headerName: "Date",
    valueGetter: (params) => {
      return Functions.DateFunctions.formatDateObjectOrNA(params.value);
    },
    ...headerText,
    ...commonColumnProps,
  },
  {
    field: "regularHours",
    headerName: "Reg. Hours",
    ...headerText,
    ...cellRender,
    ...commonColumnProps,
  },
  {
    field: "overTimeHours",
    headerName: "OT Hours",
    ...headerText,
    ...cellRender,
    ...commonColumnProps,
  },
  {
    field: "doubleOverTimeHours",
    headerName: "DOT Hours",
    ...headerText,
    ...cellRender,
    ...commonColumnProps,
  },
  {
    field: "dayTotalHours",
    headerName: "Total Hours",
    ...headerText,
    ...cellRender,
    ...commonColumnProps,
  },
  {
    field: "approveStatus",
    headerName: "Status",
    ...headerText,
    renderCell: () => (
      <Text
        style={{
          backgroundColor: Colors.LightGreen,
          border: "1px solid " + Colors.Green2,
          padding: "2px 12px 2px 12px",
          borderRadius: 16,
        }}
        color={Colors.Green4}
      >
        Approved
      </Text>
    ),
    ...commonColumnProps,
    flex: 0.5,
    minWidth: 120,
  },
];
