// React Imports
import { FC, useState } from "react";

// UI Imports
import { Box, SxProps } from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridColumnHeaderParams,
  GridRenderCellParams,
} from "@mui/x-data-grid";

// Functional Imports
import CONSTANTS from "../../utils/Constants";
import Functions from "../../utils/Functions";
import Colors from "../../utils/Colors";

// Component Imports
import CStatusChip from "../../common/CStatusChip";
import EmptyList from "../../common/EmptyList";
import Text from "../../common/Text";

interface RequestSubmissionsProps {
  disableVirtualization?: boolean;
  sx?: SxProps;
  tableData?: any;
  tableLoading?: boolean;

  testId?: string;
  isTesting?: boolean;
}

const RequestSubmissions: FC<RequestSubmissionsProps> = (props) => {
  const [pagination, setPagination] = useState({
    pageSize: 10,
    page: 0,
  });

  return (
    <Box data-testid="request-submissions-list">
      {!props.tableLoading && props.tableData?.length === 0 && (
        <EmptyList
          sx={{
            p: 8,
          }}
        />
      )}
      {(props.tableLoading || props.tableData?.length > 0) && (
        <DataGrid
          loading={props.tableLoading}
          disableVirtualization={props.disableVirtualization}
          data-testid={props.testId}
          slotProps={{
            pagination: {
              labelRowsPerPage: "",
            },
          }}
          sx={{
            backgroundColor: Colors.White,
            width: "95%",
            height: 400,
            ml: 4,
            ...props.sx,
          }}
          onPaginationModelChange={setPagination}
          paginationModel={pagination}
          pageSizeOptions={[10, 20, 50, 100]}
          rows={props.tableData}
          getRowId={(row) => row.id}
          columns={columns}
          rowSelection={false}
        />
      )}
    </Box>
  );
};

export default RequestSubmissions;

const headerText = {
  renderHeader: (params: GridColumnHeaderParams) => {
    return (
      <Text fontSize={16} fontWeight={700}>
        {params.colDef?.headerName}
      </Text>
    );
  },
};

const commonColumnProps = {
  disableColumnMenu: true,
  sortable: false,
  headerClassName: "documents-list-header",
};

const columns: GridColDef[] = [
  {
    field: "requestType",
    headerName: "Request Type",
    ...headerText,
    valueGetter: (params) => {
      let requestTypeVal = "";
      if (params.value === "EndProject") {
        requestTypeVal = "End Project";
      } else if (params.value === "ChangeRate") {
        requestTypeVal = "Change Rate";
      }
      return requestTypeVal;
    },
    minWidth: 140,
    flex: 1,
    ...commonColumnProps,
  },
  {
    field: "effectiveDate",
    headerName: "Effective Date",
    ...headerText,
    valueGetter: (params) => {
      return Functions.DateFunctions.formatDateObjectOrNA(params.value);
    },
    minWidth: 180,
    flex: 1,
    ...commonColumnProps,
  },
  {
    field: "initiatedDate",
    headerName: "Initiated Date",
    ...headerText,
    valueGetter: (params) => {
      return Functions.DateFunctions.formatDateObjectOrNA(params.value);
    },
    minWidth: 180,
    flex: 1,
    ...commonColumnProps,
  },
  {
    field: "status",
    headerName: "Status",
    ...headerText,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <CStatusChip
          type={params.value}
          options={CONSTANTS.STATUS_CHIP.REQUEST_SUBMISSIONS}
        />
      );
    },
    minWidth: 180,
    flex: 0.5,
    ...commonColumnProps,
  },
];