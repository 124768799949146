// React Imports
import { FC } from "react";

// UI Imports
import { ButtonBase, SxProps } from "@mui/material";

// Functional Imports
import Colors from "../../utils/Colors";

// Component Imports
import Text from "../Text";

interface ClearAllFiltersButtonProps {
  onClick?: any;
  sx?: SxProps;
  testId?: string;
}

const ClearAllFiltersButton: FC<ClearAllFiltersButtonProps> = (props) => {
  return (
    <ButtonBase
      onClick={props.onClick}
      sx={{
        p: 0.5,
        px: 1,
        ":hover": {
          backgroundColor: Colors.Grey7,
          borderRadius: 8,
        },
        ...props.sx,
      }}
      data-testid={props.testId}
    >
      <Text fontWeight={500} fontSize={16}>
        Clear All Filters
      </Text>
    </ButtonBase>
  );
};

export default ClearAllFiltersButton;
