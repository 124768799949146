import { Schedule } from "@mui/icons-material";

const BASEURL = process.env.REACT_APP_BASEURL;
const SERVER = {
  V4: BASEURL + "v4",
  NEW: BASEURL + "jobProvider-api/api",
  GOOGLEAPI: BASEURL + "jobProvider-api/api/Jobs/get-location",
  JOBPROVIDER: BASEURL + "jobProvider-api/api",
  INTEGRATION_API: BASEURL + "integration-api/api",
  IDPROVIDER_API: BASEURL + "idProvider-api/api",
  CANDIDATE_API: BASEURL + "candidate-api/api",
  PROJECT_SERVICE_API: BASEURL + "projectService-api",
  WORKER_SERVICE_API: BASEURL + "projectService-api",
};

const ENDPOINTS = {
  ACCOUNTS: {
    LOGIN: SERVER.IDPROVIDER_API + "/Contact/vln-login",
    LOGOUT: SERVER.IDPROVIDER_API + "/Contact/logout",
    CHECK_CREATEPASSWORD_LINKVALIDATION:
      SERVER.IDPROVIDER_API + "/Contact/check-createpassword-linkvalidation",
    EMAIL_VALIDATION: SERVER.IDPROVIDER_API + "/Contact/email-validation",
    VALIDATE_FORGOTPASSWORD_LINK:
      SERVER.IDPROVIDER_API + "/Contact/validate-forgotpassword-link",
    RESETPASSWORD: SERVER.IDPROVIDER_API + "/Contact/reset-password",
    CHANGE_PASSWORD: SERVER.IDPROVIDER_API + "/Contact/change-password",
  },
  PROPOSE_TIME_SLOTS: {
    INTERVIEW_SLOTS:
      SERVER.CANDIDATE_API + "/CandidateSubmission/interview-availability",
  },
  APPLICATIONS: {
    APPLICATIONS: SERVER.V4 + "/applications",
    APPLICATION_FILTER_DATA:
      SERVER.V4 + "/applications/application-filter-data",
    GETAPPLICATION: SERVER.CANDIDATE_API + "/CandidateSubmission/GetSubmission",
  },
  JOBS: {
    JOBS: SERVER.NEW + "/Jobs/",
    UPDATE_JOB_STATUS_POSTFIX: "/update-job-status",
    GETJOB: SERVER.NEW + "/Jobs/GetJob",
    JOB_FILTER_DATA: SERVER.NEW + "/Jobs/job-filter-data",
    INTERVIEW_SLOTS: SERVER.V4 + "/applications/interview-slots",
    JOB_APPLICATION:
      SERVER.CANDIDATE_API + "/CandidateSubmission/GetSubmissions",
    JOB_BY_STAFFLINE_ID: SERVER.JOBPROVIDER + "/Jobs/job-by-stafflineid/",
    RECENT_JOBS: SERVER.JOBPROVIDER + "/Jobs/recent-jobs",
  },
  JOB: {
    CREATEJOB: SERVER.JOBPROVIDER + "/Jobs/add",
    UPDATEJOB: SERVER.JOBPROVIDER + "/Jobs/update",
    ENHANCE_JD: SERVER.V4 + "/jobs/create-enhance-job-description",
    ZIPCODE: SERVER.GOOGLEAPI,
    GOOGLEAPI_KEY: process.env.REACT_APP_GOOGKEAPI_KEY,
    GETJOBBYID: SERVER.JOBPROVIDER + "/Jobs",
  },
  MASTERS: {
    ZIPCITY: SERVER.JOBPROVIDER + "/master/city_state_country_by_postalCode/",
    CITYAUTO: SERVER.JOBPROVIDER + "/master/jobcs-location",
    CITYDATA: SERVER.JOBPROVIDER + "/master/city-state-country-by_cityId/",

    INTERVIEWTYPE: SERVER.V4 + "/master/interview-types",
    EMPLOYMENTTYPE: SERVER.V4 + "/master/assignment-types",
  },
  EXIMIUS: {
    JOB_DETAILS: SERVER.INTEGRATION_API + "/Eximius/JobDetails",
    RESET_COUNT: SERVER.INTEGRATION_API + "/Eximius/delete-api_counter",
    GET_COUNT: SERVER.INTEGRATION_API + "/Eximius/exmius-attempts",
  },
  CANDIDATE_SUBMISSION: {
    GET_SUBMISSIONS:
      SERVER.CANDIDATE_API + "/CandidateSubmission/GetSubmissions",
    SUBMISSIONS_FILTER_DATA:
      SERVER.CANDIDATE_API + "/CandidateSubmission/submissions-filter-data",
    SHARE_APPLICATION:
      SERVER.CANDIDATE_API + "/CandidateSubmission/share_application",
    BOOKMARK_SUBMISSIONS:
      SERVER.CANDIDATE_API + "/CandidateSubmission/bookmark-submissions",
    DE_BOOKMARK_SUBMISSIONS:
      SERVER.CANDIDATE_API + "/CandidateSubmission/de-bookmark-submissions",
    HIRE: SERVER.CANDIDATE_API + "/CandidateSubmission/hire",
    REJECT: SERVER.CANDIDATE_API + "/CandidateSubmission/Reject",
    RESUME: SERVER.CANDIDATE_API + "/CandidateSubmission/resume",
    INTERVIEW_DETAILS:
      SERVER.CANDIDATE_API + "/CandidateSubmission/interview-availabilities/",
    JOBSUBMISSIONS_FOR_JIRAVIEW:
      SERVER.CANDIDATE_API + "/CandidateSubmission/jobsubmissions-for-jiraview",
    RECENT_SUBMISSIONS:
      SERVER.CANDIDATE_API + "/CandidateSubmission/recent-submissions",
    SUBMISSION_DOCUMENT:
      SERVER.CANDIDATE_API + "/CandidateSubmission/submission-document",
    SUBMISSION_BY_JOB_RESUME_ID:
      SERVER.CANDIDATE_API + "/CandidateSubmission/submission-by-jobresumeid/",
  },
  SCHEDULE_INTERVIEW_DASHBOARD: {
    SCHEDULE_INTERVIEW_API:
      SERVER.CANDIDATE_API + "/CandidateSubmission/scheduled-interviews",
  },
  CONTACT: {
    USERS_FILTER_DATA: SERVER.IDPROVIDER_API + "/Contact/users-filter-data",
    USERS: SERVER.IDPROVIDER_API + "/Contact/users",
    UPDATE_USER_STATUS: "/update-user-status",
    CONTACT: SERVER.IDPROVIDER_API + "/Contact/",
    ADD_USER: SERVER.IDPROVIDER_API + "/Contact/adduser",
  },
  USERS: {
    USERBYID: SERVER.IDPROVIDER_API + "/Contact/user-detail",
    UPDATEUSER: SERVER.IDPROVIDER_API + "/Contact/updateuser",
  },
  PROJECT: {
    PROJECT: SERVER.PROJECT_SERVICE_API + "/Project",
    PROJECT_FILTER_DATA:
      SERVER.PROJECT_SERVICE_API + "/Project/project-filter-data",
    PROJECT_DETAIL: SERVER.PROJECT_SERVICE_API + "/Project/project-detail/",
    WORKER: SERVER.PROJECT_SERVICE_API + "/Worker",
    WORKER_FILTER_DATA:
      SERVER.PROJECT_SERVICE_API + "/Worker/worker-filter-data",
    TIMESHEET: SERVER.PROJECT_SERVICE_API + "/TimeSheet/project",
    TIMESHEET_LATEST_APPROVED_PROJECT:
      SERVER.PROJECT_SERVICE_API + "/TimeSheet/latestApprovedProject",
    REQUEST_SUBMISSION_ENDPROJECT:
      SERVER.PROJECT_SERVICE_API + "/Project/request-submission-endproject",
  },
  WORKER: {
    WORKER_DETAIL: SERVER.WORKER_SERVICE_API + "/Worker/worker-detail/",
    TIMESHEET: SERVER.WORKER_SERVICE_API + "/TimeSheet/worker",
    REQUEST_SUBMISSION_ENDWORKER: SERVER.WORKER_SERVICE_API + "/Worker/request-submission-endproject",
  },
};


export default ENDPOINTS;
