// React Imports
import { FC, useEffect } from "react";

// UI Imports
import { Avatar, Box, Paper, SxProps } from "@mui/material";
import { FaCaretUp } from "react-icons/fa6";

// Functional Imports
import { Link, useNavigate } from "react-router-dom";
import API from "../../api/API";
import Paths from "../../routes/Paths";
import Images from "../../utils/Images";
import Colors from "../../utils/Colors";
import Functions from "../../utils/Functions";

// Component Imports
import ProfileHeaderActionTile from "./ProfileHeaderActionTile";
import ImageBox from "../../common/ImageBox";
import Text from "../../common/Text";

interface ProfileActionsProps {
  setHoverActionsEnabled?: any;
  sx?: SxProps;

  isTesting?: boolean;
}

const ProfileActions: FC<ProfileActionsProps> = (props) => {
  const navigate = useNavigate();

  const userName = Functions.LocalStorage.getString(
    Functions.LocalStorage.KEYS.USER_NAME
  );

  const emailId = Functions.LocalStorage.getString(
    Functions.LocalStorage.KEYS.EMAIL_ID
  );

  const initials = Functions.ValueRetrieve.getInitals(String(userName));

  useEffect(() => {
    if (props.isTesting) {
      handleChangePasswordClick();
      handleContactUsClick();
    }
  }, []);

  const handleLogout = async () => {
    try {
      await API.logout();
    } catch (error) {
      alert("Logout failed. Please try again.");
    }
  };

  const handleClosePopover = () => {
    props.setHoverActionsEnabled(false);
  };

  const handleChangePasswordClick = () => {
    navigate(Paths.CHANGE_PASSWORD);
    handleClosePopover();
  };

  const handleContactUsClick = () => {
    window.open(Paths.CONTACT_US_INTERNAL, "_blank");
    handleClosePopover();
  };

  return (
    <Paper
      data-testid="profile-actions-ui"
      sx={{
        width: 240,
        borderRadius: 1,
        ...props.sx,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          p: 1,
          pt: 2,
        }}
      >
        <Avatar
          sx={{
            height: 32,
            width: 32,
            backgroundColor: Colors.Blue7,
            fontSize: 14,
            fontWeight: 700,
            fontFamily: "Roboto",
          }}
        >
          {initials}
        </Avatar>
        <Box
          sx={{
            width: "70%",
          }}
        >
          <Text fontWeight={700}>{userName}</Text>
          <Text fontSize={10}>{emailId}</Text>
        </Box>
        <FaCaretUp
          style={{
            cursor: "pointer",
          }}
          color={Colors.Blue7}
          onClick={handleClosePopover}
        />
      </Box>
      <ProfileHeaderActionTile
        onClick={handleChangePasswordClick}
        title="Change Password"
        icon={
          <ImageBox
            src={Images.ChangePasswordIcon}
            sx={{
              height: 20,
              width: 20,
            }}
          />
        }
        sx={{
          borderTop: "1px solid " + Colors.Grey23,
        }}
      />

      <ProfileHeaderActionTile
        onClick={handleContactUsClick}
        title="Help Center"
        icon={
          <ImageBox
            src={Images.HelpCentreIcon}
            sx={{
              height: 20,
              width: 20,
            }}
          />
        }
      />
      <ProfileHeaderActionTile
        testId="logout-btn"
        title="Logout"
        icon={
          <ImageBox
            src={Images.LogoutIcon}
            sx={{
              height: 20,
              width: 20,
            }}
          />
        }
        onClick={handleLogout}
      />
    </Paper>
  );
};

export default ProfileActions;
