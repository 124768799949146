import Colors from "./Colors";
import ENUMS from "./Enums";

const REGEX_TYPES = {
  PASSWORD: /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,16}$/,
  ATLEAST_ONE_UPPERCASE_AND_ONE_LOWERCASE: /(?=.*[a-z])(?=.*[A-Z])/,
  MINIMUM_8_CHARACTER_MAXIMUM_16_CHARACTER: /^(?=.*\w).{8,16}$/,
  AT_LEAST_1_NUMBER: /\d/,
  SPECIAL_CHARACTER: /(?=.*[!@#$%?=*&])/,
  PASSWORD2:
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,16}$/,
  EMAIL: /^\S+@\S+\.\S+$/,
  NO_SPECIAL_CHARACTER: /^[a-zA-Z0-9]{4,10}$/,
  VALID_NAME: /^[A-Za-z ]*$/,
};

const REQUEST_SUBMISSIONS_STATUS_CHIP_TYPES = [
  {
    borderColor: Colors.Orange,
    backgroundColor: Colors.LightOrange,
    textColor: Colors.Brown,
    type: ENUMS.REQUEST_SUBMISSIONS_STATUS_TYPES.CLOSED,
  },
  {
    backgroundColor: Colors.Grey4,
    borderColor: Colors.Grey3,
    textColor: Colors.Black1,
    type: ENUMS.REQUEST_SUBMISSIONS_STATUS_TYPES.PENDING,
  },
];

const ACTIVE_INACTIVE_REVIEW_CHIP_TYPES = [
  {
    backgroundColor: Colors.LightRed,
    borderColor: Colors.Red2,
    textColor: Colors.Red3,
    type: ENUMS.JOB_STATUS_TYPES.INACTIVE,
  },
  {
    borderColor: Colors.Green2,
    backgroundColor: Colors.LightGreen,
    textColor: Colors.Green4,
    type: ENUMS.JOB_STATUS_TYPES.ACTIVE,
  },
  {
    textColor: Colors.DarkBlue2,
    borderColor: Colors.Blue1,
    backgroundColor: Colors.LightBlue2,
    type: ENUMS.JOB_STATUS_TYPES.IN_REVIEW,
  },
];

const APPLICATION_STATUS_CHIP_TYPES = [
  {
    backgroundColor: Colors.Grey4,
    borderColor: Colors.Grey3,
    textColor: Colors.Black1,
    type: ENUMS.APPLICATION_STATUS_TYPES.PENDING_REVIEW,
  },
  {
    borderColor: Colors.Orange,
    backgroundColor: Colors.LightOrange,
    textColor: Colors.Brown,
    type: ENUMS.APPLICATION_STATUS_TYPES.INTERVIEW_REQUESTED,
  },
  {
    textColor: Colors.DarkBlue,
    borderColor: Colors.LightBlue3,
    backgroundColor: Colors.LightBlue4,
    type: ENUMS.APPLICATION_STATUS_TYPES.INTERVIEWED,
  },
  {
    borderColor: Colors.Red2,
    backgroundColor: Colors.LightRed,
    textColor: Colors.Red3,
    type: ENUMS.APPLICATION_STATUS_TYPES.REJECTED,
  },
  {
    borderColor: Colors.Green2,
    backgroundColor: Colors.LightGreen,
    textColor: Colors.Green4,
    type: ENUMS.APPLICATION_STATUS_TYPES.HIRED,
  },
  {
    textColor: Colors.DarkBlue2,
    borderColor: Colors.Blue1,
    backgroundColor: Colors.LightBlue2,
    type: ENUMS.APPLICATION_STATUS_TYPES.INTERVIEW_SCHEDULED,
  },
];

const STATUS_CHIP_TYPES = {
  REQUEST_SUBMISSIONS: REQUEST_SUBMISSIONS_STATUS_CHIP_TYPES,
  ACTIVE_INACTIVE_REVIEW: ACTIVE_INACTIVE_REVIEW_CHIP_TYPES,
  APPLICATION: APPLICATION_STATUS_CHIP_TYPES,
};

const CONSTANTS = {
  REGEX: REGEX_TYPES,
  STATUS_CHIP: STATUS_CHIP_TYPES,
};

export default CONSTANTS;
