// React Imports
import { FC } from "react";

// UI Imports
import { Box, SxProps, TextFieldProps } from "@mui/material";

// Functional Imports
import Colors from "../../utils/Colors";

// Component Imports
import Text from "../../common/Text";
import {
  DatePicker,
  DateValidationError,
  LocalizationProvider,
  PickerChangeHandlerContext,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

interface DateSelectProps {
  label: string;
  required?: boolean;
  sx?: SxProps;
  value?: any;
  onChange?: (
    value: unknown,
    context: PickerChangeHandlerContext<DateValidationError>
  ) => void;
  error?: boolean;
  helperText?: string | false;
  handleBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  id?: string;
  name?: string;
  textFieldProps?: TextFieldProps;
  disabled?: boolean;
  minValues?: any;
  datePickerProps?: any;

  testId?: string;
  dateTestId?: string;
}

const DateSelect: FC<DateSelectProps> = (props) => {
  return (
    <Box
      data-testid={props.testId}
      sx={{
        ...props.sx,
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: 0.3,
        }}
      >
        <Text color={Colors.Grey9}>{props.label}</Text>
        {props.required && <Text color={Colors.Red}>*</Text>}
      </Box>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker          
          data-testid={props.dateTestId}
          value={props.value}
          format="MM/DD/YYYY"
          name="startDate"
          disabled={props.disabled}
          slotProps={{
            textField: {
              inputProps: {
                "data-testid": props.dateTestId,
                style: {
                  backgroundColor: Colors.Grey15,
                },
              },
              error: props.error,
              helperText: props.helperText,
              onBlur: props.handleBlur,
              id: props.id,
              name: props.name,
              variant: "outlined",
              size: "small",
              sx: {
                width: "100%",
                margin: "6px 8px 8px 0px",
              },
              ...props.textFieldProps,
            },
          }}
          onChange={props.onChange}
          {...props.datePickerProps}
        />
      </LocalizationProvider>
    </Box>
  );
};

export default DateSelect;
