// Images
const LogoTagline = require("../assets/images/logo-tagline.png");
const BackgroundLogin = require("../assets/images/background-login.png");
const VelocityLogo = require("../assets/images/velocity-logo.png");
const NoPendingApproval = require("../assets/images/no-pending-approval.png");

// Icons
const MailIcon = require("../assets/icons/mail-icon.png");
const BellIcon = require("../assets/icons/bell-icon.png");
const UploadIcon = require("../assets/icons/upload-icon.png");
const AnimatedLoader = require("../assets/icons/animated-loader.gif");
const EmptyApplicationsList = require("../assets/images/empty-applications-list.png");
const BriefcaseYellowIcon = require("../assets/icons/briefcase-yellow-icon.png");
const DocumentBlueIcon = require("../assets/icons/document-blue-icon.png");
const DocumentUserIcon = require("../assets/icons/document-user-icon.png");
const DoubleDownBlueIcon = require("../assets/icons/double-down-blue-icon.png");
const DoubleUpBlueIcon = require("../assets/icons/double-up-blue-icon.png");
const UserVoiletIcon = require("../assets/icons/user-voilet-icon.png");
const FolderPurpleIcon = require("../assets/icons/folder-purple-icon.png");
const UserSettingsOrangeIcon = require("../assets/icons/user-settings-orange-icon.png");
const TimesheetBlueIcon = require("../assets/icons/timesheet-blue-icon.png");
const ChangePasswordIcon = require("../assets/icons/change-password-icon.png");
const HelpCentreIcon = require("../assets/icons/help-centre-icon.png");
const LogoutIcon = require("../assets/icons/logout-icon.png");

const Images = {
  MailIcon,
  VelocityLogo,
  BellIcon,
  UploadIcon,
  BriefcaseYellowIcon,
  DocumentBlueIcon,
  DocumentUserIcon,
  DoubleDownBlueIcon,
  DoubleUpBlueIcon,
  UserVoiletIcon,
  FolderPurpleIcon,
  UserSettingsOrangeIcon,
  TimesheetBlueIcon,
  ChangePasswordIcon,
  HelpCentreIcon,
  LogoutIcon,

  LogoTagline,
  BackgroundLogin,
  EmptyApplicationsList,
  AnimatedLoader,
  NoPendingApproval,
};

export default Images;
