// React Imports
import { FC } from "react";

// UI Imports
import { Paper, SxProps } from "@mui/material";
import { GiHamburgerMenu } from "react-icons/gi";
import { RiFlag2Line } from "react-icons/ri";

// Functional Imports
import { useNavigate } from "react-router-dom";
import Paths from "../../routes/Paths";
import Colors from "../../utils/Colors";

// Component Imports
import HoverActionTile from "../../common/HoverActionTile";

interface HoverActionWorkersProps {
  id?: string;
  status?: string;
  handleEndProject?: any;
  setHoverActionsEnabled?: any;
  row?: any;
  isEndProjectSubmitted?: boolean;
  sx?: SxProps;
}

const HoverActionWorkers: FC<HoverActionWorkersProps> = (props) => {
  const navigate = useNavigate();
  return (
    <Paper
      data-testid="hover-actions-workers-popup"
      sx={{
        backgroundColor: Colors.White,
        width: 200,
        borderRadius: 1,
        ...props.sx,
      }}
    >
      <HoverActionTile
        testId="view-details-btn"
        icon={<GiHamburgerMenu size={20} color={Colors.Blue7} />}
        onClick={() => navigate(Paths.WORKER + "/" + props.id)}
        title="View Details"
      />
      {!props.isEndProjectSubmitted && (
        <HoverActionTile
          testId="end-project-btn"
          icon={<RiFlag2Line size={20} color={Colors.Blue7} />}
          title="End Project"
          onClick={() => {
            props.handleEndProject(props.row);
            props.setHoverActionsEnabled(false);
          }}
        />
      )}
    </Paper>
  );
};

export default HoverActionWorkers;
