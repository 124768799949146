// React Imports
import { FC, useState } from "react";

// UI Imports
import {
  Avatar,
  Box,
  ButtonBase,
  IconButton,
  Popover,
} from "@mui/material";
import { FaCaretDown } from "react-icons/fa";
import { FaRegBell } from "react-icons/fa6";

// Functional Imports
import { useLocation, useNavigate } from "react-router-dom";
import Paths from "../../routes/Paths";
import Images from "../../utils/Images";
import Colors from "../../utils/Colors";
import Functions from "../../utils/Functions";

// Component Imports
import ProfileActions from "./ProfileActions";

interface HeaderProps {
  isdashboard?: any;
  setIsdashboard?: any;
  menuexpand?: any;
  setMenuexpand?: any;
}

const Header: FC<HeaderProps> = (props) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [hoverActionsEnabled, setHoverActionsEnabled] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const id = hoverActionsEnabled ? "simple-popover" : undefined;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (hoverActionsEnabled) {
      setAnchorEl(null);
      setHoverActionsEnabled(false);
    } else {
      setAnchorEl(event.currentTarget);
      setHoverActionsEnabled(true);
    }
  };

  const userName = Functions.LocalStorage.getString(
    Functions.LocalStorage.KEYS.USER_NAME
  );

  const initials = Functions.ValueRetrieve.getInitals(String(userName));

  return (
    <Box
      data-testid="header-comp"
      sx={{
        height: "10vh",
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        backgroundColor: Colors.White,
        alignItems: "center",
        position: "relative",
        borderBottom: "1px solid " + Colors.Grey2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          component={"img"}
          src={Images.VelocityLogo}
          sx={{
            height: 50,
            marginLeft:
              location.pathname.includes(Paths.DASHBOARD) && !props.menuexpand
                ? 5
                : 1,
            pl: 4,
            cursor: "pointer",
          }}
          onClick={() => {
            navigate(Paths.DASHBOARD);
          }}
        />
      </Box>
      <Box
        sx={{
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 2,
          mr: 6,
        }}
      >
        <IconButton
          sx={{
            height: 32,
            width: 32,
            borderRadius: 0.5,
            border: "1px solid " + Colors.LightBlue9,
          }}
        >
          <FaRegBell color={Colors.Blue7} size={16} />
        </IconButton>
        <ButtonBase
          data-testid="show-hide-actions"
          onClick={handleClick}
          sx={{
            height: 40,
            width: 72,
            borderRadius: 3.5,
            border: "1px solid " + Colors.LightBlue9,
            gap: 1,
          }}
        >
          <Avatar
            sx={{
              height: 32,
              width: 32,
              backgroundColor: Colors.Blue7,
              fontSize: 14,
              fontWeight: 700,
              fontFamily: "Roboto",
            }}
          >
            {initials}
          </Avatar>
          <FaCaretDown color={Colors.Blue7} size={16} />
        </ButtonBase>
      </Box>
      <Popover
        id={id}
        open={hoverActionsEnabled}
        anchorEl={anchorEl}
        onClose={() => setHoverActionsEnabled(false)}
      >
        <ProfileActions setHoverActionsEnabled={setHoverActionsEnabled} />
      </Popover>
    </Box>
  );
};

export default Header;
