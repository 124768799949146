// React Imports
import { FC, useEffect, useState } from "react";

// UI Imports
import { Box, ButtonBase, IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

// Functional Imports
import { object, string } from "yup";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import API from "../../api/API";
import Paths from "../../routes/Paths";
import Colors from "../../utils/Colors";
import Toasts from "../../utils/Toasts";
import STRINGS from "../../utils/Strings";
import Functions from "../../utils/Functions";

// Component Imports
import LogoTaglineChild from "../../common/LogoTaglineChild";
import ButtonFilled from "../../common/ButtonFilled";
import LabelInput from "../../common/LabelInput";
import Text from "../../common/Text";

interface LoginPageProps {
  isTesting?: boolean;
  initialiseFormik?: boolean;
}

const LoginPage: FC<LoginPageProps> = (props) => {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  const formik = useFormik({
    initialValues: { email: "", password: "" },
    enableReinitialize: true,
    validationSchema: object({
      email: string()
        .required(STRINGS.LOGIN_PAGE.EMAIL_IS_REQUIRED)
        .email(STRINGS.LOGIN_PAGE.ENTER_VALID_EMAIL),
      password: string().required(STRINGS.LOGIN_PAGE.PASSWORD_IS_REQUIRED),
    }),
    onSubmit: (values) => {
      API.login(values.email, values.password)
        .then((response) => {
          if (response.isError) {
            Toasts.error("No account found with this email ID");
          } else {
            if (response.message?.appStatusDescription === "Login success") {
              Functions.LocalStorage.storeString(
                Functions.LocalStorage.KEYS.JWT_TOKEN,
                response.entity?.token
              );
              Functions.LocalStorage.storeString(
                Functions.LocalStorage.KEYS.USER_ID,
                response.entity?.loggedInUserId
              );
              Functions.LocalStorage.storeString(
                Functions.LocalStorage.KEYS.USER_NAME,
                response.entity?.name
              );
              Functions.LocalStorage.storeString(
                Functions.LocalStorage.KEYS.EMAIL_ID,
                response.entity?.email_Id
              );

              window.location.reload();
            } else {
              Toasts.error(response.message?.appStatusDescription);
            }
          }
        })
        .catch((e) => {
          Toasts.error(e);
        });
    },
  });

  useEffect(() => {
    if (props.isTesting) {
      if (props.initialiseFormik) {
        formik
          .setValues({
            email: "test@test.com",
            password: "Password",
          })
          .then(() => {
            formik.handleSubmit();
          });
      }
    }
  }, []);

  return (
    <LogoTaglineChild testId="login-page">
      <Box
        className="login-page"
        sx={{
          p: 4,
          display: "flex",
          flexDirection: "column",
          gap: 1.5,
        }}
      >
        <Text fontWeight={700} fontSize={24}>
          Welcome to Velocity
        </Text>
        <Box>
          <Text fontSize={16}>Login with your registered email ID.</Text>
        </Box>

        <LabelInput
          label="Email ID"
          required={true}
          textFieldProps={{
            placeholder: "Enter email",
            sx: {
              width: { xs: "100%", sm: 300 },
              mt: 1,
            },
            id: "email",
            name: "email",
            value: formik.values.email,
            onChange: formik.handleChange,
            onBlur: formik.handleBlur,
            error: formik.touched.email && Boolean(formik.errors.email),
            helperText: formik.touched.email && formik.errors.email,
          }}
        />
        <Box className="password-input-field">
          <LabelInput
            label="Password"
            required={true}
            textFieldProps={{
              placeholder: "Enter password",
              sx: {
                width: { xs: "100%", sm: 300 },
                mt: 1,
              },
              type: showPassword ? "text" : "password",
              InputProps: {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      data-testid={"show-password-btn"}
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? (
                        <Visibility
                          sx={{
                            height: 20,
                            width: 20,
                            color: Colors.Black1,
                          }}
                        />
                      ) : (
                        <VisibilityOff
                          sx={{
                            height: 20,
                            width: 20,
                            color: Colors.Black1,
                          }}
                        />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              },
              id: "password",
              name: "password",
              value: formik.values.password,
              onChange: formik.handleChange,
              onBlur: formik.handleBlur,
              error: formik.touched.password && Boolean(formik.errors.password),
              helperText: formik.touched.password && formik.errors.password,
            }}
          />
          <Box
            sx={{
              width: { xs: "100%", sm: 300 },
              mt: 0.5,
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <ButtonBase
              data-testid="forgot-password-btn"
              onClick={() => {
                navigate(Paths.FORGOT_PASSWORD, {
                  state: { email: formik.values.email },
                });
              }}
            >
              <Text fontWeight={700} fontSize={16} color={Colors.Blue7}>
                Forgot Password?
              </Text>
            </ButtonBase>
          </Box>
        </Box>

        <ButtonFilled
          sx={{
            width: 60,
          }}
          testId="login-btn"
          onClick={() => formik.handleSubmit()}
          title="Login"
        />
      </Box>
    </LogoTaglineChild>
  );
};

export default LoginPage;
