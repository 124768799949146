// React Imports
import { FC, useEffect, useState } from "react";

// UI Imports
import { Box } from "@mui/material";

// Functional Imports
import { Link, useParams, useSearchParams } from "react-router-dom";
import API from "../../api/API";
import Paths from "../../routes/Paths";
import dayjs from "dayjs";
import ENUMS from "../../utils/Enums";
import Colors from "../../utils/Colors";
import Functions from "../../utils/Functions";

// Component Imports
import RequestSubmissions from "../../common/RequestSubmissions";
import ProjectDetailTab from "./ProjectDetailTab";
import EndProjectPopup from "../../common/EndProjectPopup";
import AttributeValue from "../../common/AttributeValue";
import DocumentsList from "../../common/DocumentsList";
import ButtonFilled from "../../common/ButtonFilled";
import TabSelector from "../../common/TabSelector";
import Breadcrumbs from "../../common/Breadcrumbs";
import Timesheet from "../../common/Timesheet";
import Loader from "../../common/Loader";
import Text from "../../common/Text";

interface ProjectDetailsProps {
  isTesting?: boolean;
}

const ProjectDetails: FC<ProjectDetailsProps> = (props) => {
  const { id } = useParams();

  const [searchParams] = useSearchParams();

  const tab = searchParams.get("tab");
  const date = searchParams.get("date");

  const [projectDetailData, setProjectDetailData] = useState(
    projectDetailDataInit
  );
  const [dataLoading, setDataLoading] = useState(false);
  const [tabSelected, setTabSelected] = useState(
    ENUMS.PROJECT_TAB_OPTIONS.PROJECT_DETAILS
  );
  const [documentListData, setDocumentListData] = useState([]);
  const [documentLoading, setDocumentLoading] = useState(false);

  const [projectDetailId, setProjectDetailId] = useState("");
  const [month, setMonth] = useState(dayjs(new Date()));
  const [weeklyData, setWeeklyData] = useState<any>([]);
  const [timesheetLoading, setTimesheetLoading] = useState(false);
  const [maxMonth, setMaxMonth] = useState(dayjs(new Date()));

  const [endProjectPopupOpen, setEndProjectPopupOpen] = useState(false);
  const [endProjectPopupProjectId, setEndProjectPopupProjectId] =
    useState<any>("");
  const [endProjectPopupWorkerName, setEndProjectPopupWorkerName] =
    useState("");
  const [endProjectPopupProjectName, setEndProjectPopupProjectName] =
    useState("");
  const [endProjectPopupStartDate, setEndProjectPopupStartDate] =
    useState<any>("");
  const [isNotEndProjectRequestSubmitted, setIsNotEndProjectRequestSubmitted] =
    useState(false);

  useEffect(() => {
    if (props.isTesting) {
      handleEndProject();
    }
  }, []);

  useEffect(() => {
    if (tab === "timesheet") {
      setTabSelected(ENUMS.PROJECT_TAB_OPTIONS.TIMESHEET);
    }

    getProjectDetails();
  }, [id]);

  useEffect(() => {
    if (!!projectDetailId) {
      getTimesheets(projectDetailId, month);
    }
  }, [month]);

  const getProjectDetails = () => {
    setDataLoading(true);
    API.getProjectDetailsByID(String(id))
      .then((response) => {
        setDataLoading(false);
        setProjectDetailData(response.entity);
        getDocuments(String(response.entity?.jobResumeId));
        setProjectDetailId(response.entity?.projectDetailId);

        setIsNotEndProjectRequestSubmitted(
          !Functions.Validation.checkEndProjectRequestSubmitted(
            response.entity?.requestSubmissions
          )
        );

        let monthPass = month;

        if (dayjs(date).isValid()) {
          monthPass = dayjs(date);
          setMonth(monthPass);
        } else if (dayjs(response.entity?.startDate).isAfter(new Date())) {
          setMonth(dayjs(response.entity?.startDate));
          setMaxMonth(dayjs(response.entity?.startDate));
        }

        getTimesheets(response.entity?.projectDetailId, monthPass);
      })
      .catch((e) => {
        setDataLoading(false);
      });
  };

  const getTimesheets = (projectDetailId: string, month: any) => {
    let payload = {
      ProjectDetailId: projectDetailId,
      StartDate: month.startOf("month").format(),
      EndDate: month.endOf("month").format(),
    };

    setTimesheetLoading(true);
    API.getProjectTimesheet(payload)
      .then((response) => {
        setTimesheetLoading(false);
        let weeklyDataPass = Functions.ValueRetrieve.processDatesWeekly(
          response.entityList
        );

        if (!!date || props.isTesting) {
          const weeklyValues = Functions.DateFunctions.getWeekStartAndEnd(date);

          weeklyDataPass = weeklyDataPass.map((item: any) => ({
            ...item,
            expanded: false,
          }));

          weeklyDataPass.map((item: any, index: number) => {
            if (item.weekStart === weeklyValues.weekStart) {
              weeklyDataPass[index].expanded = true;
            }
          });
        }

        setWeeklyData([...weeklyDataPass]);
      })
      .catch((e) => {
        setTimesheetLoading(false);
      });
  };

  const getDocuments = (jobResumeId: string) => {
    setDocumentLoading(true);
    API.getDocumentsByJobResumeId(jobResumeId)
      .then((response) => {
        setDocumentLoading(false);
        setDocumentListData(response.entity?.documentDetails);
      })
      .catch((e) => {
        setDocumentLoading(false);
      });
  };

  const handleEndProject = () => {
    setEndProjectPopupOpen(true);
    setEndProjectPopupProjectId(id);
    setEndProjectPopupWorkerName(projectDetailData.employeeName);
    setEndProjectPopupProjectName(projectDetailData.projectName);
    setEndProjectPopupStartDate(projectDetailData.startDate);
  };

  return (
    <Box
      data-testid="project-details-page"
      sx={{
        height: "90vh",
        width: "100%",
        backgroundColor: Colors.LightWhite,
        overflowY: "scroll",
      }}
    >
      {dataLoading && <Loader />}
      <Box
        sx={{
          width: "100%",
          pl: 4,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: Colors.White,
          py: 1,
          position: "relative",
        }}
      >
        <Breadcrumbs
          sx={{
            pt: 1,
            pb: 1,
          }}
          path={[
            { path: Paths.DASHBOARD, name: "Home" },
            { path: Paths.PROJECTS, name: "Projects" },
            { name: projectDetailData.projectId },
          ]}
        />
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mt: 1.5,
        }}
      >
        <Text
          fontSize={20}
          fontWeight={600}
          style={{
            marginTop: 16,
            marginLeft: 32,
          }}
        >
          Details
        </Text>
        <Box
          sx={{
            gap: 2,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mr: 5,
          }}
        >
          {isNotEndProjectRequestSubmitted && (
            <ButtonFilled onClick={handleEndProject} title="End Project" />
          )}
        </Box>
      </Box>
      <Box
        sx={{
          backgroundColor: Colors.White,
          height: 75,
          width: "95%",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          mx: 4,
          mt: 2,
        }}
      >
        <AttributeValue
          label="Project name"
          value={projectDetailData.projectName}
        />
        <Box
          sx={{
            pl: 4,
            width: "18%",
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <Text fontWeight={400} fontSize={14}>
            Worker Name
          </Text>
          <Link to={Paths.WORKER + "/" + id}>
            <Text color={Colors.Blue5} fontWeight={700} fontSize={14}>
              {projectDetailData.employeeName}
            </Text>
          </Link>
        </Box>
        <AttributeValue
          label="Start Date"
          value={Functions.DateFunctions.formatDateObjectOrNA(
            projectDetailData.startDate
          )}
        />
        <AttributeValue
          label="End Date"
          value={Functions.DateFunctions.formatDateObjectOrNA(
            projectDetailData.endDate,
            "MM/DD/YYYY"
          )}
        />
      </Box>
      <TabSelector
        options={
          projectDetailData.requestSubmissions?.length > 0
            ? [
                ENUMS.PROJECT_TAB_OPTIONS.PROJECT_DETAILS,
                ENUMS.PROJECT_TAB_OPTIONS.TIMESHEET,
                ENUMS.PROJECT_TAB_OPTIONS.DOCUMENTS,
                ENUMS.PROJECT_TAB_OPTIONS.REQUEST_SUBMISSIONS,
              ]
            : [
                ENUMS.PROJECT_TAB_OPTIONS.PROJECT_DETAILS,
                ENUMS.PROJECT_TAB_OPTIONS.TIMESHEET,
                ENUMS.PROJECT_TAB_OPTIONS.DOCUMENTS,
              ]
        }
        selectedOption={tabSelected}
        setSelectedOption={setTabSelected}
      />
      {tabSelected === ENUMS.PROJECT_TAB_OPTIONS.PROJECT_DETAILS && (
        <ProjectDetailTab projectDetailData={projectDetailData} />
      )}
      {tabSelected === ENUMS.PROJECT_TAB_OPTIONS.TIMESHEET && (
        <Timesheet
          month={month}
          setMonth={setMonth}
          weeklyData={weeklyData}
          setWeeklyData={setWeeklyData}
          dataLoading={timesheetLoading}
          setDataLoading={setTimesheetLoading}
          projectStartDate={projectDetailData.startDate}
          maxMonth={maxMonth}
        />
      )}
      {tabSelected === ENUMS.PROJECT_TAB_OPTIONS.DOCUMENTS && (
        <DocumentsList
          tableData={documentListData}
          tableLoading={documentLoading}
          sx={{
            width: "95%",
          }}
        />
      )}
      {tabSelected === ENUMS.PROJECT_TAB_OPTIONS.REQUEST_SUBMISSIONS && (
        <RequestSubmissions
          tableData={projectDetailData.requestSubmissions}
          tableLoading={dataLoading}
        />
      )}
      <EndProjectPopup
        open={endProjectPopupOpen}
        setOpen={setEndProjectPopupOpen}
        setUpdateDataAgain={() => getProjectDetails()}
        projectId={endProjectPopupProjectId}
        startDate={endProjectPopupStartDate}
        workerName={endProjectPopupWorkerName}
        projectName={endProjectPopupProjectName}
      />
    </Box>
  );
};

export default ProjectDetails;

const projectDetailDataInit = {
  projectDetailId: "",
  projectId: "",
  projectName: "",
  employeeName: "",
  startDate: null,
  endDate: null,
  projectStatus: "",
  billRate: null,
  otBillRate: null,
  accountManager: "",
  requestSubmissions: [],
};
