// React Imports
import { FC } from "react";

// UI Imports
import { Paper, SxProps } from "@mui/material";
import { GiHamburgerMenu } from "react-icons/gi";

// Functional Imports
import { useNavigate } from "react-router-dom";
import Paths from "../../routes/Paths";
import Colors from "../../utils/Colors";

// Component Imports
import HoverActionTile from "../../common/HoverActionTile";

interface HoverActionTimesheetsProps {
  id?: string;
  date?: string;
  sx?: SxProps;
}

const HoverActionTimesheets: FC<HoverActionTimesheetsProps> = (props) => {
  const navigate = useNavigate();

  return (
    <Paper
      data-testid="hover-actions-timesheets-popup"
      sx={{
        backgroundColor: Colors.White,
        width: 200,
        borderRadius: 1,
        ...props.sx,
      }}
    >
      <HoverActionTile
        testId="view-details-btn"
        icon={<GiHamburgerMenu size={20} color={Colors.Blue7} />}
        title="View Details"
        onClick={() => {
          navigate(
            Paths.PROJECT + "/" + props.id + "?tab=timesheet&date=" + props.date
          );
        }}
      />
    </Paper>
  );
};

export default HoverActionTimesheets;
