// React Imports
import { FC, useEffect, useState } from "react";

// UI Imports
import {
  IconButton,
  InputAdornment,
  SxProps,
  TextFieldProps,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

// Functional Imports
import Colors from "../../utils/Colors";

// Component Imports
import LabelInput from "../LabelInput";

interface PasswordInputProps {
  textFieldProps?: TextFieldProps;
  label: string;
  required?: boolean;
  placeholder?: string;
  sx?: SxProps;
  textFieldSx?: SxProps;

  isTesting?: boolean;
  testId?: string;
}

const PasswordInput: FC<PasswordInputProps> = (props) => {
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (props.isTesting) {
      disableCopyPaste({ preventDefault: () => {} });
      toggleShowPassword();
      toggleShowPassword();
    }
  }, []);

  const disableCopyPaste = (e: any) => {
    e.preventDefault();
  };

  const toggleShowPassword = () => setShowPassword(!showPassword);

  return (
    <LabelInput
      testId={props.testId}
      sx={props.sx}
      label={props.label}
      required={props.required}
      textFieldProps={{
        placeholder: props.placeholder,
        sx: {
          width: 300,
          ...props.textFieldSx,
        },
        type: showPassword ? "text" : "password",
        InputProps: {
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                data-testid={"show-password-btn"}
                onClick={toggleShowPassword}
                edge="end"
              >
                {showPassword ? (
                  <Visibility
                    sx={{
                      height: 20,
                      width: 20,
                      color: Colors.Black1,
                    }}
                  />
                ) : (
                  <VisibilityOff
                    sx={{
                      height: 20,
                      width: 20,
                      color: Colors.Black1,
                    }}
                  />
                )}
              </IconButton>
            </InputAdornment>
          ),
        },
        onPaste: disableCopyPaste,
        onCut: disableCopyPaste,
        ...props.textFieldProps,
      }}
    />
  );
};

export default PasswordInput;
