// React Imports
import { FC, useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

// UI Imports
import { Box, Grid, Switch, SwitchProps } from "@mui/material";
import { useFormik } from "formik";

// Functional Imports
import Functions from "../../utils/Functions";
import STRINGS from "../../utils/Strings";
import Colors from "../../utils/Colors";
import Toasts from "../../utils/Toasts";
import styled from "@emotion/styled";
import Paths from "../../routes/Paths";
import API from "../../api/API";
import * as Yup from "yup";

// Component Imports
import ButtonFilled from "../../common/ButtonFilled";
import Breadcrumbs from "../../common/Breadcrumbs";
import LabelInput from "../../common/LabelInput";
import Loader from "../../common/Loader";
import Text from "../../common/Text";
import "./Useroverview.css";

interface UsereditProps {
  isTesting?: boolean;
}

const Useredit: FC<UsereditProps> = (props) => {
  const [userData, setUserData] = useState({
    id: "",
    firstname: "",
    lastname: "",
    emailId: "",
    isEmailEditable: "",
    status: "",
    role: "",
    userId: "",
  });
  const [loading, setLoading] = useState(false);
  const [activeJobs, setActiveJobs] = useState(false);

  const Params = useParams();
  const navigate = useNavigate();
  const userid = Params.userid;

  const formik = useFormik({
    initialValues: userData,
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      firstname: Yup.string().required("This field is required"),
      lastname: Yup.string().required("This field is required"),
      emailId: Yup.string()
        .required("This field is required")
        .email("Please enter valid details"),
    }),
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values) => {
      updateUser(values);
    },
  });

  useEffect(() => {
    if (props.isTesting) {
      const valuepass: any = {
        id: "f82d3ba9-5171-48ec-97d2-57e5f455454f",
        firstname: "velocity",
        lastname: "vln",
        emailId: "velocity@vln.com",
        isEmailEditable: "false",
        status: "active",
        role: ["Admin"],
      };
      getuserdetail();
      chnageactivejob();
      updateUser(valuepass);
      formik.setValues(valuepass).then(() => {
        formik.handleSubmit();
      });
    }
    getuserdetail();
  }, []);

  const getuserdetail = () => {
    setLoading(true);
    API.getUserByID(userid)
      .then((response) => {
        if (response?.status === 200) {
          let data = response?.entity;
          setUserData({
            id: String(userid),
            firstname: data?.firstName,
            lastname: data?.lastName,
            emailId: data?.emailId,
            isEmailEditable: data?.isEmailEditable,
            status: data?.status,
            role: data?.role[0],
            userId: data?.userId,
          });
          setActiveJobs(data?.status == "Active" ? true : false);
        }
        setLoading(false);
      })
      .catch((e) => {
        Toasts.error(e);
        setUserData({
          id: "",
          firstname: "",
          lastname: "",
          emailId: "",
          isEmailEditable: "",
          status: "",
          role: "",
          userId: "",
        });
        setLoading(false);
      });
  };

  const updateUser = (payload: any) => {
    setLoading(true);
    API.UPDATEUSER(payload)
      .then((response) => {
        if (response?.status === 200) {
          Toasts.success("User Detail has been edited successfully!");
          navigate(Paths.USER + "/" + response?.entity?.id);
        } else {
          Toasts.error(response.message?.appStatusDescription);
        }
        setLoading(false);
      })
      .catch((e) => {
        Toasts.error(e);
        setLoading(false);
      });
  };

  const IOSSwitch = styled((props: SwitchProps) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "100ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor: Colors.Blue7,
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: Colors.Blue7,
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {},
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.7,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: "#E9E9EA",
      opacity: 1,
    },
  }));

  const chnageactivejob: any = () => {
    setActiveJobs((activeJobs) => !activeJobs);
    let newStatus = activeJobs ? "Inactive" : "Active";
    setLoading(true);

    API.updateUserStatus(userid, newStatus)
      .then((response) => {
        formik.setFieldValue("status", newStatus);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  return (
    <Box
      data-testid="edit-user-page"
      className="edit-user-page"
      sx={{
        height: "90vh",
        width: "100%",
        backgroundColor: Colors.LightWhite,
        overflowY: "scroll",
      }}
    >
      {loading && <Loader />}
      <Box
        sx={{
          width: "100%",
          display: "flex",
          pl: 4,
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: Colors.White,
          py: 2,
          position: "relative",
        }}
      >
        <Breadcrumbs
          sx={{
            pt: 0,
          }}
          path={[
            { path: Paths.DASHBOARD, name: "Home" },
            { path: Paths.USERS, name: "Users" },
            { name: userData?.firstname + " " + userData?.lastname },
          ]}
        />
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          p: 4,
          justifyContent: "space-between",
          alignItems: "center",
          py: 1,
          position: "relative",
        }}
      >
        <Text fontWeight={700} fontSize={24}>
          {STRINGS.COMMONSTR.DETAIL}
        </Text>
        <ButtonFilled
          title={STRINGS.COMMONSTR.SAVE}
          onClick={formik.handleSubmit}
        />
      </Box>
      <Box
        sx={{
          backgroundColor: Colors.White,
          ml: 4,
          mr: 4,
          p: 2,
          pl: 4,
          height: "72vh",
        }}
      >
        <Box
          sx={{
            height: 60,
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Text fontWeight={700} fontSize={16}>
            {STRINGS.CREATE_JOB.REQUIRED_DETAIL}
          </Text>
        </Box>
        <Box
          sx={{
            width: "100%",
            mb: 8,
          }}
        >
          <Grid
            container
            spacing={1}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xs={3} md={3} mt={3}>
              <LabelInput
                label={"First Name"}
                required={true}
                textFieldProps={{
                  sx: {
                    width: { xs: "20%", sm: 275 },
                    mt: 1,
                  },
                  inputProps: {
                    onKeyDown: Functions.Validation.restrictToOnlyAlphabets,
                    sx: {
                      backgroundColor: Colors.White,
                      fontSize: 16,
                      color: Colors.Black1,
                    },
                  },
                  value: Functions.ValueRetrieve.toCamelCase(
                    formik?.values?.firstname
                  ),
                  name: "firstname",
                  id: "firstname",
                  onChange: (ev) => {
                    formik?.setFieldValue("firstname", ev.target.value);
                  },
                  onBlur: formik?.handleBlur,
                  error: Functions.Sonar.booleanAnd(
                    Boolean(formik?.touched?.firstname),
                    Boolean(formik?.errors?.firstname)
                  ),
                  helperText: Functions.Sonar.booleanAndString(
                    Boolean(formik.touched?.firstname),
                    formik.errors?.firstname
                  ),
                }}
              />
            </Grid>
            <Grid item xs={3} md={3} mt={3}>
              <LabelInput
                label={"Last Name"}
                required={true}
                textFieldProps={{
                  sx: {
                    width: { xs: "20%", sm: 275 },
                    mt: 1,
                  },
                  inputProps: {
                    onKeyDown: Functions.Validation.restrictToOnlyAlphabets,
                    sx: {
                      backgroundColor: Colors.White,
                      fontSize: 16,
                      color: Colors.Black1,
                    },
                  },
                  name: "lastname",
                  id: "lastname",
                  value: Functions.ValueRetrieve.toCamelCase(
                    formik?.values?.lastname
                  ),
                  onChange: (ev) => {
                    formik?.setFieldValue("lastname", ev.target.value);
                  },
                  onBlur: formik?.handleBlur,
                  error: Functions.Sonar.booleanAnd(
                    Boolean(formik?.touched?.lastname),
                    Boolean(formik?.errors?.lastname)
                  ),
                  helperText: Functions.Sonar.booleanAndString(
                    Boolean(formik.touched?.lastname),
                    formik.errors?.lastname
                  ),
                }}
              />
            </Grid>
            <Grid item xs={3} md={3} mt={3}>
              <LabelInput
                label={"Name"}
                required={true}
                inputProps={{
                  readOnly: true,
                }}
                textFieldProps={{
                  disabled: true,
                  sx: {
                    width: { xs: "20%", sm: 275 },
                    mt: 1,
                  },
                  inputProps: {
                    disabled: true,
                    sx: {
                      backgroundColor: Colors.White,
                      fontSize: 16,
                      color: Colors.Black1,
                    },
                  },
                  value: Functions.ValueRetrieve.toCamelCase(
                    formik?.values?.firstname + " " + formik?.values?.lastname
                  ),
                }}
              />
            </Grid>

            <Grid item xs={3} md={3} mt={3}>
              {!!userData.isEmailEditable ? (
                <LabelInput
                  label={"Email ID"}
                  required={true}
                  textFieldProps={{
                    sx: {
                      width: { xs: "20%", sm: 275 },
                      mt: 1,
                    },
                    inputProps: {
                      sx: {
                        backgroundColor: Colors.White,
                        fontSize: 16,
                        color: Colors.Black1,
                      },
                    },
                    name: "emailId",
                    id: "emailId",
                    value: formik?.values?.emailId,
                    onChange: (ev) => {
                      formik?.setFieldValue("emailId", ev.target.value);
                    },
                    onBlur: formik?.handleBlur,
                    error: Functions.Sonar.booleanAnd(
                      Boolean(formik?.touched?.emailId),
                      Boolean(formik?.errors?.emailId)
                    ),
                    helperText: Functions.Sonar.booleanAndString(
                      Boolean(formik.touched?.emailId),
                      formik.errors?.emailId
                    ),
                  }}
                />
              ) : (
                <LabelInput
                  label={"Email ID"}
                  required={true}
                  inputProps={{
                    readOnly: true,
                  }}
                  textFieldProps={{
                    disabled: true,
                    sx: {
                      width: { xs: "20%", sm: 275 },
                      mt: 1,
                    },
                    inputProps: {
                      sx: {
                        backgroundColor: Colors.White,
                        fontSize: 16,
                        color: Colors.Black1,
                      },
                    },
                    name: "emailId",
                    id: "emailId",
                    value: formik?.values?.emailId,
                  }}
                />
              )}
            </Grid>

            <Grid item xs={3} md={3} mt={3}>
              <Box
                sx={{
                  mr: 2,
                  width: "17%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: 0.3,
                    marginTop: "0px",
                  }}
                >
                  <Text color={Colors.Grey9}>Status</Text>
                  {<Text color={Colors.Red}>*</Text>}
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flax-start",
                    alignItems: "center",
                    p: 0,
                    mt: 2,
                  }}
                >
                  <div>
                    <Text fontWeight={700} fontSize={14}>
                      Active
                    </Text>
                  </div>
                  <IOSSwitch
                    sx={{ ml: 1 }}
                    checked={activeJobs}
                    onChange={chnageactivejob}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={3} md={3} mt={3}>
              <LabelInput
                label={"Role"}
                required={true}
                inputProps={{
                  readOnly: true,
                }}
                textFieldProps={{
                  disabled: true,
                  sx: {
                    width: { xs: "20%", sm: 275 },
                    mt: 1,
                  },
                  inputProps: {
                    sx: {
                      backgroundColor: Colors.White,
                      fontSize: 16,
                      color: Colors.Black1,
                    },
                  },
                  value: formik?.values?.role,
                }}
              />
            </Grid>
            <Grid item xs={3} md={3} mt={3}></Grid>
            <Grid item xs={3} md={3} mt={3}></Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default Useredit;
