import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { toast } from 'react-toastify';
import STRINGS from './Strings';

dayjs.extend(customParseFormat);

export const isValidTime = (time: string) => /^([01]\d|2[0-3]):([0-5]\d)$/.test(time);

export const calculateTimeDifference = (startTime: string, endTime: string) => {
  const start = dayjs(startTime, STRINGS.PROPOSE_TIME_SLOTS.TIME_FORMAT);
  const end = dayjs(endTime, STRINGS.PROPOSE_TIME_SLOTS.TIME_FORMAT);

  if (start.isValid() && end.isValid() && start.isBefore(end)) {
    const diff = end.diff(start, 'minute');
    const hours = Math.floor(diff / 60);
    const minutes = diff % 60;

    let result = '';
    if (hours > 0) {
      result = `${hours}h`;
    }
    if (minutes > 0) {
      result += ` ${minutes}m`;
    }
    return result || `${minutes}m`;
  }
  return '';
};

export const handleTimeChange = (
  setter: React.Dispatch<React.SetStateAction<string>>,
  value: string,
  otherValue: string,
  isFromTime: boolean,
  setTimeDifference: React.Dispatch<React.SetStateAction<string>>
) => {
  setter(value);

  if (isValidTime(value)) {
    const startTime = isFromTime ? value : otherValue;
    const endTime = isFromTime ? otherValue : value;

    const diff = calculateTimeDifference(startTime, endTime);

    setTimeDifference(diff);
  } else {
    setTimeDifference('');
  }
};


