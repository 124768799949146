// React Imports
import { FC, ReactNode } from "react";

// UI Imports
import { Box, ButtonBase, SxProps } from "@mui/material";
import { FaChevronRight } from "react-icons/fa6";

// Functional Imports
import Colors from "../../utils/Colors";

// Component Imports
import DashboardTileRow from "./DashboardTileRow";
import Text from "../../common/Text";

interface DashboardTileProps {
  borderColorTop?: string;
  sx?: SxProps;
  topIcon?: ReactNode;
  title?: string;
  subTitle?: string;
  entries?: any[];
  listIcon?: ReactNode;
  isExpanded?: boolean;
  handleClick?: any;
  id?: string;
  onMainClick?: any;
  disableMainClick?: boolean;
  hoverType?: any;
  emptyText?: string;
  dataLoading?: boolean;
  emptyImageOrText?: boolean;
  emptyImage?: ReactNode;

  testId?: string;
}

const DashboardTile: FC<DashboardTileProps> = (props) => {
  return (
    <Box
      data-testid={props.testId}
      sx={{
        width: "32%",
        height: props.isExpanded ? 360 : 84,
        border: "1px solid " + Colors.Grey17,
        backgroundColor: Colors.White,
        borderRadius: 1.5,
        ...props.sx,
      }}
    >
      <ButtonBase
        disabled={props.disableMainClick}
        onClick={props.onMainClick}
        sx={{
          width: "100%",
          height: 84,
          borderRadius: 1,
        }}
      >
        <Box
          sx={{
            m: 2,
            height: 45,
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderLeft: "4px solid " + props.borderColorTop,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 1,
              ml: 1,
            }}
          >
            {props.topIcon}
            <Text fontSize={20} fontWeight={500}>
              {props.title}
            </Text>
          </Box>
          <Box
            sx={{
              height: 30,
              width: 30,
              backgroundColor: Colors.LightBlue6,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 16,
            }}
          >
            <FaChevronRight size={14} color={Colors.Blue2} />
          </Box>
        </Box>
      </ButtonBase>
      {props.isExpanded && (
        <>
          <Text
            style={{
              marginLeft: 16,
            }}
            fontWeight={600}
            fontSize={16}
          >
            {props.subTitle}
          </Text>
          <Box
            sx={{
              height: "1px",
              width: "90%",
              // mt: 1,
              mx: 2,
              // p: 2,
              backgroundColor: Colors.Grey18,
            }}
          />
          {props.entries?.length === 0 && (
            <Text
              style={{
                marginTop: 40,
                textAlign: "center",
              }}
              fontWeight={400}
              fontSize={16}
            >
              {(!props.dataLoading &&
                !props.emptyImageOrText &&
                props.emptyText) ||
                (props.emptyImageOrText && props.emptyImage)}
            </Text>
          )}
          {props.entries?.map((item: any) => {
            return (
              <DashboardTileRow
                key={item.topTitle + item.bottomTitle}
                icon={props.listIcon}
                topTitle={item.topTitle}
                bottomTitle={item.bottomTitle}
                id={item.bottomTitle}
                handleClick={(ev: any) =>
                  props.handleClick(ev, item.otherDetails)
                }
                hoverType={props.hoverType}
                // sx={{
                // }}
              />
            );
          })}
        </>
      )}
    </Box>
  );
};

export default DashboardTile;
