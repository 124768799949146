// React Imports
import { FC, ReactNode } from "react";

// UI Imports
import { ButtonBase, SxProps } from "@mui/material";

// Functional Imports
import Colors from "../../utils/Colors";
import Text from "../../common/Text";

// Component Imports

interface ProfileHeaderActionTileProps {
  title?: string;
  icon?: ReactNode;
  onClick?: any;
  sx?: SxProps;
  disableRipple?: boolean;

  testId?: string;
}

const ProfileHeaderActionTile: FC<ProfileHeaderActionTileProps> = (props) => {
  return (
    <ButtonBase
      disableRipple={props.disableRipple}
      data-testid={props.testId}
      onClick={props.onClick}
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        py: 2,
        gap: 1,
        flex: 1,
        width: "85%",
        mx: 2,
        borderBottom: "1px solid " + Colors.Grey23,
        ...props.sx,
      }}
    >
      {props.icon}
      <Text>{props.title}</Text>
    </ButtonBase>
  );
};

export default ProfileHeaderActionTile;
