// React Imports
import { FC, useEffect, useState } from "react";

// UI Imports
import { Box, ButtonBase, TablePagination } from "@mui/material";
import { FaAngleDoubleUp, FaAngleDoubleDown } from "react-icons/fa";

// Functional Imports
import Functions from "../../utils/Functions";
import Colors from "../../utils/Colors";

// Component Imports
import HierarchyBlock from "./HierarchyBlock";
import EmptyList from "../../common/EmptyList";
import Loader from "../../common/Loader";
import Text from "../../common/Text";

interface ApplicationHierarchyProps {
  hierarchyViewData?: any;
  setHierarchyViewData?: any;
  tableLoading?: boolean;
  pageIndex: number;
  pageSize: number;
  setPagination?: any;
  totalEntries?: number;
  sortModel?: any;
  setUpdateDataAgain?: any;
  updateDataAgain?: boolean;
  setSortModel?: any;
  onShare?: any;
  onHire?: any;
  onReject?: any;
  onResumeOpen?: any;
  onScheduleInterviewOpen?: any;

  isTesting?: boolean;
}

const ApplicationHierarchy: FC<ApplicationHierarchyProps> = (props) => {
  const [isAllCollapsed, setAllCollapsed] = useState(false);
  const [isAllExpanded, setAllExpanded] = useState(false);

  useEffect(() => {
    if (props.isTesting) {
      handleExpandAll();
      handleCollapseAll();
      handleExpanded(0);
      handlePageChange({}, 0);
      handleRowsPerPageChange({ target: { value: 0 } });
    }
  }, []);

  useEffect(() => {
    let isAllExpandedPass = true;
    let isAllCollapsedPass = true;

    props.hierarchyViewData?.map((item: any) => {
      isAllCollapsedPass = isAllCollapsedPass && !item.expanded;
      isAllExpandedPass = isAllExpandedPass && item.expanded;
    });

    setAllCollapsed(isAllCollapsedPass);
    setAllExpanded(isAllExpandedPass);
  }, [props.hierarchyViewData]);

  const handleExpandAll = () => {
    const tempHierarchyViewData = props.hierarchyViewData?.map(
      (item: any, index: number) => {
        let expanded = true;

        if (!item.submissions?.length) {
          expanded = false;
        }

        return {
          ...item,
          expanded: expanded,
        };
      }
    );
    props.setHierarchyViewData([...tempHierarchyViewData]);
  };

  const handleCollapseAll = () => {
    let tempHierarchyViewData = props.hierarchyViewData?.map((item: any) => ({
      ...item,
      expanded: false,
    }));
    props.setHierarchyViewData([...tempHierarchyViewData]);
  };

  const handleExpanded = (index: number) => {
    let tempHierarchyViewData = props.hierarchyViewData;
    tempHierarchyViewData[index].expanded =
      !tempHierarchyViewData[index].expanded;
    props.setHierarchyViewData([...tempHierarchyViewData]);
  };

  const handlePageChange = (ev: any, pageIndex: number) => {
    props.setPagination({
      pageIndex: pageIndex,
      pageSize: props.pageSize,
    });
    props.setUpdateDataAgain(!props.updateDataAgain);
  };

  const handleRowsPerPageChange = (ev: any) => {
    props.setPagination({
      pageIndex: 0,
      pageSize: ev.target.value,
    });
    props.setUpdateDataAgain(!props.updateDataAgain);
  };

  return (
    <Box
      data-testid={"application-hierarchy"}
      sx={{
        backgroundColor: Colors.LightWhite,
        width: "calc(95vw - 30px)",
        height: "calc(90vh - 120px)",
        marginTop: 1.5,
        overflowY: "auto",
        ml: 4,
      }}
    >
      <Box
        sx={{
          minHeight: "calc(100% - 56px)",
        }}
      >
        {(props.isTesting || props.hierarchyViewData?.length > 0) && (
          <Box sx={{ pt: 0, ml: 0, display: "flex", alignItems: "center" }}>
            <ButtonBase
              disabled={isAllExpanded}
              onClick={handleExpandAll}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                p: 1,
                gap: 1,
              }}
            >
              <FaAngleDoubleDown
                color={isAllExpanded ? Colors.Blue7 + "AA" : Colors.Blue7}
              />
              <Text
                fontSize={12}
                fontWeight={700}
                color={isAllExpanded ? Colors.Blue7 + "AA" : Colors.Blue7}
              >
                Expand all
              </Text>
            </ButtonBase>

            <ButtonBase
              disabled={isAllCollapsed}
              onClick={handleCollapseAll}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                p: 1,
                gap: 1,
              }}
            >
              <FaAngleDoubleUp
                color={isAllCollapsed ? Colors.Blue7 + "AA" : Colors.Blue7}
              />
              <Text
                fontSize={12}
                fontWeight={700}
                color={isAllCollapsed ? Colors.Blue7 + "AA" : Colors.Blue7}
              >
                Collapse all
              </Text>
            </ButtonBase>
          </Box>
        )}
        {props.hierarchyViewData?.map((item: any, index: number) => {
          let jobLocation = Functions.ValueRetrieve.getLocation(
            item.jobCountryCode,
            item.jobStateCode,
            item.jobCity
          );

          return (
            <HierarchyBlock
              expanded={item.expanded}
              setExpanded={(ev: any) => handleExpanded(index)}
              key={item.jobId}
              jobId={item.clientRequestId}
              jobTitle={item.jobTitle}
              jobPostedDate={Functions.DateFunctions.formatDateObject(
                item.jobPostedDate,
                "MM/DD/YYYY"
              )}
              showMore={item.showMore}
              jobLocation={jobLocation}
              jobApplications={item.submissions}
              noOfApplications={item.noOfApplications}
              updateDataAgain={props.updateDataAgain}
              setUpdateDataAgain={props.setUpdateDataAgain}
              onShare={props.onShare}
              onHire={props.onHire}
              onReject={props.onReject}
              onResumeOpen={props.onResumeOpen}
              onScheduleInterviewOpen={props.onScheduleInterviewOpen}
            />
          );
        })}

        {(props.isTesting ||
          (!props.tableLoading && props.hierarchyViewData?.length === 0)) && (
          <EmptyList
            sx={{
              mt: 20,
            }}
          />
        )}
      </Box>
      {(props.isTesting || props.tableLoading) && <Loader />}
      {props.hierarchyViewData?.length > 0 && (
        <TablePagination
          sx={{
            mr: 2,
          }}
          labelRowsPerPage={""}
          component="div"
          count={Number(props.totalEntries)}
          rowsPerPageOptions={[10, 20, 50, 100]}
          page={props.pageIndex}
          onPageChange={handlePageChange}
          rowsPerPage={props.pageSize}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      )}
    </Box>
  );
};

export default ApplicationHierarchy;
