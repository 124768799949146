// React Imports
import { FC, useEffect, useState } from "react";

// UI Imports
import { Box, IconButton, Popover } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { BsThreeDotsVertical } from "react-icons/bs";

// Functional Imports
import Functions from "../../utils/Functions";
import Colors from "../../utils/Colors";

// Component Imports
import HoverActionWorkers from "./HoverActionWorkers";

interface ActionTileWorkerListProps extends Partial<GridRenderCellParams> {
  updateDataAgain?: boolean;
  setUpdateDataAgain?: any;
  handleEndProject?: any;

  isTesting?: boolean;
}

const ActionTileWorkerList: FC<ActionTileWorkerListProps> = (props) => {
  const [hoverActionsEnabled, setHoverActionsEnabled] = useState(false);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  useEffect(() => {
    if (props.isTesting) {
      const param: any = {};
      handleClick(param);
    }
  }, []);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (hoverActionsEnabled) {
      setAnchorEl(null);
      setHoverActionsEnabled(false);
    } else {
      setAnchorEl(event.currentTarget);
      setHoverActionsEnabled(true);
    }
  };

  const id = hoverActionsEnabled ? "simple-popover" : undefined;

  const isEndProjectSubmitted =
    Functions.Validation.checkEndProjectRequestSubmitted(
      props.row?.requestSubmissions
    );

  return (
    <Box
      data-testid="action-tile-project-list"
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 0,
        position: "relative",
      }}
    >
      <IconButton
        sx={{
          borderRadius: 2,
          backgroundColor: hoverActionsEnabled ? Colors.Grey12 : null,
        }}
        data-testid="menu-btn"
        onClick={(ev) => handleClick(ev)}
      >
        <BsThreeDotsVertical size={20} color={Colors.Black1} />
      </IconButton>
      <Popover
        id={id}
        open={hoverActionsEnabled}
        anchorEl={anchorEl}
        onClose={() => setHoverActionsEnabled(false)}
        anchorOrigin={{
          vertical: 40,
          horizontal: 10,
        }}
      >
        <HoverActionWorkers
          id={props.row?.id}
          status={props.row?.jobStatus}
          handleEndProject={props.handleEndProject}
          setHoverActionsEnabled={setHoverActionsEnabled}
          row={props.row}
          isEndProjectSubmitted={isEndProjectSubmitted}
        />
      </Popover>
    </Box>
  );
};

export default ActionTileWorkerList;
