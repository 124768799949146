// React Imports
import React, { FC } from "react";

// UI Imports
import {
  DataGrid,
  GridColDef,
  GridColumnHeaderParams,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import { SxProps } from "@mui/material";

// Functional Imports
import { Link } from "react-router-dom";
import Paths from "../../routes/Paths";
import Colors from "../../utils/Colors";
import CONSTANTS from "../../utils/Constants";
import Functions from "../../utils/Functions";

// Component Imports
import ActionTileApplication from "./ActionTileApplication";
import CStatusChip from "../../common/CStatusChip";
import EmptyList from "../../common/EmptyList";
import Loader from "../../common/Loader";
import Text from "../../common/Text";

interface ApplicationListProps {
  disableVirtualization?: boolean;
  sx?: SxProps;
  tableData?: any;
  tableLoading?: boolean;
  pageIndex: number;
  pageSize: number;
  setPagination?: any;
  totalEntries?: number;
  sortModel?: any;
  setUpdateDataAgain?: any;
  updateDataAgain?: boolean;
  setSortModel?: any;
  onShare?: any;
  onHire?: any;
  onReject?: any;
  onResumeOpen?: any;
  onScheduleInterviewOpen?: any;
  hideFooter?: boolean;

  testId?: string;
}

const ApplicationList: FC<ApplicationListProps> = (props) => {
  const applicationListColumns = [
    {
      field: "actions",
      headerName: "Actions",
      ...headerText,
      renderCell: (params: GridRenderCellParams) => (
        <ActionTileApplication
          {...params}
          updateDataAgain={props.updateDataAgain}
          setUpdateDataAgain={props.setUpdateDataAgain}
          onShare={props.onShare}
          onHire={props.onHire}
          onReject={props.onReject}
          onResumeOpen={props.onResumeOpen}
          onScheduleInterviewOpen={props.onScheduleInterviewOpen}
        />
      ),
      width: 180,
      ...commonColumnProps,
      sortable: false,
    },
    ...columns,
  ];

  return (
    <>
      {props.tableData?.length === 0 && !props.tableLoading && (
        <EmptyList
          sx={{
            p: 12,
          }}
        />
      )}
      {(props.tableData?.length > 0 || props.tableLoading) && (
        <DataGrid
          loading={props.tableLoading}
          disableVirtualization={props.disableVirtualization}
          data-testid={props.testId}
          slots={{
            loadingOverlay: renderLoader,
          }}
          slotProps={{
            pagination: {
              labelRowsPerPage: "",
            },
          }}
          sx={{
            backgroundColor: Colors.White,
            width: "calc(95vw - 42px)",
            height: "calc(90vh - 120px)",
            marginTop: 1.5,
            ml: 4,
            ...props.sx,
          }}
          pageSizeOptions={[10, 20, 50, 100]}
          paginationModel={{
            page: props.pageIndex,
            pageSize: props.pageSize,
          }}
          paginationMode="server"
          onPaginationModelChange={(ev) => {
            props.setPagination({
              pageIndex: ev.page,
              pageSize: ev.pageSize,
            });
          }}
          rows={props.tableData}
          getRowId={(row) => row.id}
          columns={applicationListColumns}
          rowCount={props.totalEntries}
          hideFooter={props.hideFooter}
          rowSelection={false}
          sortModel={props.sortModel}
          sortingMode="server"
          onSortModelChange={(ev) => {
            props.setUpdateDataAgain(!props.updateDataAgain);
            props.setSortModel(ev);
          }}
        />
      )}
    </>
  );
};

export default ApplicationList;

const headerText = {
  renderHeader: (params: GridColumnHeaderParams) => {
    return (
      <Text fontSize={16} fontWeight={700}>
        {params.colDef?.headerName}
      </Text>
    );
  },
};

const cellRender = {
  renderCell: (params: GridRenderCellParams) => {
    return <Text>{params.row?.[params.field]}</Text>;
  },
};

const commonColumnProps = {
  disableColumnMenu: true,
};

const columns: GridColDef[] = [
  {
    field: "candidateName",
    headerName: "Name",
    ...headerText,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Link to={Paths.APPLICATIONS + "/" + params.row?.id}>
          <Text color={Colors.Blue1}>{params.row?.candidateName}</Text>
        </Link>
      );
    },
    minWidth: 140,
    flex: 1,
    ...commonColumnProps,
  },
  {
    field: "clientRequestId",
    headerName: "Job ID",
    ...headerText,
    ...cellRender,
    minWidth: 180,
    flex: 1,
    ...commonColumnProps,
  },
  {
    field: "jobTitle",
    headerName: "Job Title",
    ...headerText,
    renderCell: (params: GridRenderCellParams) => {
      return Functions.ValueRetrieve.toCamelCase(params.row?.jobTitle || "");
    },
    minWidth: 180,
    flex: 1,
    ...commonColumnProps,
  },
  {
    field: "location",
    headerName: "Location",
    ...headerText,
    valueGetter: (params) => {
      let jobLocation = Functions.ValueRetrieve.getLocation(
        params.row?.jobCountryCode,
        params.row?.jobStateCode,
        params.row?.jobCity
      );
      return jobLocation;
    },
    minWidth: 180,
    flex: 1,
    ...commonColumnProps,
  },
  {
    field: "status",
    headerName: "Status",
    ...headerText,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <CStatusChip
          type={params.row?.resumeVLNStatus}
          options={CONSTANTS.STATUS_CHIP.APPLICATION}
        />
      );
    },
    minWidth: 180,
    flex: 1,
    ...commonColumnProps,
  },
  {
    field: "experience",
    headerName: "Experience",
    ...headerText,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Text>
          {params.row?.experience != "N/A"
            ? params.row?.experience + " Years"
            : ""}{" "}
        </Text>
      );
    },
    minWidth: 180,
    flex: 1,
    ...commonColumnProps,
  },
];

const renderLoader = () => <Loader />;
