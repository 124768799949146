// React Imports
import { FC, useEffect } from "react";

// UI Imports
import { Box, Paper } from "@mui/material";
import { IoIosHelpCircleOutline } from "react-icons/io";

// Functional Imports
import Colors from "../../utils/Colors";

// Component Imports
import Text from "../../common/Text";

interface InternalContactUsProps {
  isTesting?: boolean;
}

const InternalContactUs: FC<InternalContactUsProps> = (props) => {
  useEffect(() => {
    if (props.isTesting) {
      handleMailClick();
    }
  }, []);

  const handleMailClick = () =>
    (window.location.href = "mailto:contact@velocitymsp.ai");

  return (
    <Box
      data-testid="internal-contact-us-page"
      sx={{
        height: "90vh",
        width: "100%",
        backgroundColor: Colors.LightWhite,
        overflowY: "auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Paper
        sx={{
          width: 500,
          height: 220,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          pt: 2,
        }}
      >
        <IoIosHelpCircleOutline color={Colors.Blue7} size={120} />
        <Box
          sx={{
            width: "95%",
            my: 1,
            border: "1px solid " + Colors.Grey24,
          }}
        />
        <Text
          fontSize={20}
          style={{
            textAlign: "center",
          }}
        >
          Please write to us on{" "}
          <span
            data-testid="contact-us-btn"
            style={{
              textDecoration: "underline",
              cursor: "pointer",
              color: Colors.Blue7,
              fontWeight: 700,
            }}
            onClick={handleMailClick}
          >
            contact@velocitymsp.ai
          </span>
        </Text>

        <Text
          fontSize={20}
          style={{
            textAlign: "center",
            marginBottom: 24,
            width: 390
          }}
        >
          We typically respond within 24 hours on all business days.
        </Text>
      </Paper>
    </Box>
  );
};

export default InternalContactUs;
