// React Imports
import React, { FC, useEffect, useState } from "react";

// UI Imports
import { Box, Grid, IconButton, Modal, Tooltip } from "@mui/material";
import KeyboardDoubleArrowLeftRoundedIcon from '@mui/icons-material/KeyboardDoubleArrowLeftRounded';
import { IoClose } from "react-icons/io5";
import { IoMdRefresh } from "react-icons/io";

// Functional Imports
import STRINGS from "../../utils/Strings";
import Colors from "../../utils/Colors";

// Component Imports
import ButtonFilled from "../../common/ButtonFilled/ButtonFilled";
import QuillEditor from "../../common/QuillEditor";
import Text from "../../common/Text";

interface EnhanceJDProps {
  isjdExpanded: boolean;
  setIsjdExpanded?: any;
  formikJD?: any;
  jobDescription?: any;
  formik?: any;
  handleEnhanceJDClick?: any;
  handleEditorChange?: any;
  handleNext?: any;
  attemptsLeft?: any;
  loading?: any;
  isTesting?: boolean;
}

const EnhanceJD: FC<EnhanceJDProps> = (props) => {
  const [value, setValue] = useState<any>("");


  useEffect(() => {
    if (props.isTesting) {
      handleClose();
      handleNext();
    }
  }, []);

  useEffect(() => {
    setValue(props.formik.values.jobDescription);
  }, [props.isjdExpanded]);
  const handleClose = () => {
    props.setIsjdExpanded(false);
    props.formik?.setFieldValue("enhanceJd", "");
  };
  const handleNext = () => {
    props.formik?.setFieldValue(
      "jobDescription",
      props.formik?.values?.enhanceJd
    );
    props.handleNext();
    props.formik?.setFieldValue("enhanceJd", "");
  };

  const isEmptyContent = (content: string) => {
    return !content || content.trim() === "" || content.trim() === "<p><br></p>";
  };

  const enhancedJdContent = props.formik?.values?.enhanceJd || "";
  const isEnhancedJdEmpty = isEmptyContent(enhancedJdContent);

  return (
    <>
      <Modal
        open={props.isjdExpanded}
        onClose={handleClose}
        className="enhancejdpopup"
      >
        <Box
          data-testid="enhance-jd-modal"
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "calc(100% - 32px)",
            maxWidth: "1048px",
            height: "550px",
            padding: "24px 32px",
            gap: "24px",
            borderRadius: "4px",
            backgroundColor: Colors.White,
            boxShadow: 24,
            outline: "none",
          }}
        >
          <Box
            sx={{
              width: "984px",
              height: "28px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Text fontWeight={700} fontSize={24}>
              Enhance Job Description
            </Text>
            <IconButton onClick={handleClose} data-testid="close-button">
              <IoClose />
            </IconButton>
          </Box>

          <Grid
            container
            sx={{
              width: "984px",
              height: "500px",
              gap: "8px",
              marginTop: "16px",
            }}
          >
            <Grid item xs={12}>
              <Box
                sx={{
                  width: "100%",
                  height: "370px",
                  display: "flex",
                  gap: "16px",
                }}
              >
                <Grid item xs={6}>
                  <Box
                    sx={{
                      width: "100%",
                      flexDirection: "column",
                    }}
                  >
                    <QuillEditor
                      data-testid="editor"
                      editorStyle={{
                        height: 340,
                        BorderBottom: "none",

                      }}
                      label=""
                      value={value}
                      onChange={(ev: any) => {
                        const normalizedValue = ev === "<p><br></p>" ? "" : ev;
                        setValue(normalizedValue);
                      }}
                    />

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        height: "44px",
                        padding: "10px 5px 5px 0",
                        border: "1px solid #CCCCCC",
                        borderTop: "none",
                        marginTop: 0,
                        backgroundColor: "#F4F6FF",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      ></Box>
                      <Box
                        sx={{
                          mr: 2,
                          mt: 1,
                          alignItems: "center",
                          backgroundColor: value?.length > 0 ? "#4A57A3" : "#A1A1A1",
                          width: "24px",
                          height: "25px",
                          borderRadius: "2px",
                          cursor: value?.trim() ? "pointer" : " ",
                        }}
                      >
                        <Tooltip
                          title={
                            value?.trim()
                              ? "Click to enhance the job description."
                              : "Add job description to regenerate."
                          }
                          placement="left"
                          arrow
                          slotProps={{
                            popper: {
                              modifiers: [
                                {
                                  name: "offset",
                                  options: {
                                    offset: [1, -4],
                                  },
                                },
                              ],
                            },
                          }}
                        >
                          <span style={{ cursor: value?.trim() ? "pointer" : "default" }}>
                            <IconButton
                              sx={{
                                display: "flex",
                                textTransform: "none",
                                borderRadius: 0,
                                padding: 0,
                                cursor: "pointer",
                                width: "auto"
                              }}
                              onClick={value?.trim() ? props.handleEnhanceJDClick : undefined}
                              disabled={!value || value.trim().length === 0}
                            >
                              {" "}
                              <IoMdRefresh
                                style={{
                                  color: "white",
                                  marginLeft: "-3px",
                                  marginTop: "3px",
                                  fontSize: "20px",
                                  position: "relative",
                                  top: "35%",
                                  left: "32%",
                                  cursor: "pointer"
                                }}
                              />
                            </IconButton>
                          </span>
                        </Tooltip>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Tooltip
                  title={
                    isEnhancedJdEmpty
                      ? "No enhanced job description to swap"
                      : "Swap job description to enhance again"
                  }
                  placement="bottom"
                  arrow
                  slotProps={{
                    popper: {
                      modifiers: [
                        {
                          name: "offset",
                          options: {
                            offset: [0, -10],
                          },
                        },
                      ],
                    },
                  }}
                  sx={{
                    cursor: isEnhancedJdEmpty ? "not-allowed" : "pointer",
                  }}
                >
                  <KeyboardDoubleArrowLeftRoundedIcon
                    sx={{
                      marginTop: "185px",
                      cursor: isEnhancedJdEmpty ? "" : "pointer",
                      color: isEnhancedJdEmpty ? "#A1A1A1" : "#4A57A3",
                      border: isEnhancedJdEmpty ? "1px solid #A1A1A1" : "1px solid #4A57A3",
                      borderRadius: "50%",
                    }}
                    onClick={() => {
                      if (!isEnhancedJdEmpty) {
                        setValue(enhancedJdContent);
                        props.formik?.setFieldValue("enhanceJd", "");
                      }
                    }}
                  />
                </Tooltip>
                <Grid item xs={6}>
                  <Box
                    className="second-quill-container"
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <QuillEditor
                      className="second-quill-container"
                      data-testid="editor"
                      editorStyle={{
                        height: 350,
                      }}
                      label=""
                      disabled={
                        props.formik?.values?.enhanceJd?.length > 0
                          ? false
                          : true
                      }
                      value={
                        props.formik?.values?.enhanceJd
                          ? props.formik?.values?.enhanceJd
                          : ""
                      }
                      onChange={(ev: any) => {
                        props.formik?.setFieldValue("enhanceJd", ev);
                      }}
                      placeholder="Click 'Regenerate' icon on left pane to view the Enhanced job description here."
                    />
                    <Box
                      sx={{
                        border: "1px solid #ccc",
                        height: "34px",
                        borderTop: "none",
                        backgroundColor: "#F4F6FF",
                      }}
                    ></Box>
                  </Box>
                </Grid>
              </Box>
            </Grid>

            <Box
              sx={{
                height: 60,
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                gap: 2,
              }}
            >
              <ButtonFilled
                title={STRINGS.CREATE_JOB.NEXT}
                disabled={isEnhancedJdEmpty}
                sx={{
                  backgroundColor: isEnhancedJdEmpty ? "#A1A1A1" : "#4A57A3",
                  "&.Mui-disabled": {
                    backgroundColor: "#A1A1A1 !important",
                    color: "#c0c0c0 !important",
                  },
                }}
                onClick={handleNext}
              />
            </Box>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

export default EnhanceJD;
