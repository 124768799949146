// React Imports
import { FC, useEffect, useRef, useState } from "react";

// UI Imports
import { Box } from "@mui/material";

// Functional Imports
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import API from "../../api/API";
import ENUMS from "../../utils/Enums";
import Paths from "../../routes/Paths";
import Colors from "../../utils/Colors";
import Functions from "../../utils/Functions";

// Component Imports
import ModalForProposeTimeSlot from "../Applications/ModalForProposeTimeSlot";
import ClearAllFiltersButton from "../../common/ClearAllFiltersButton";
import ApplicationHierarchy from "../Applications/ApplicationHierarchy";
import ActiveJobsSwitch from "../../common/ActiveJobsSwitch";
import FilterPopupJobs from "./FilterPopupJobs";
import ResumeDocPopup from "../../common/ResumeDocPopup";
import ResumeTxtPopup from "../../common/ResumeTxtPopup";
import ButtonFilled from "../../common/ButtonFilled";
import FilterButton from "../../common/FilterButton";
import Breadcrumbs from "../../common/Breadcrumbs";
import ActionTypes from "../../redux/action";
import ResumePopup from "../../common/ResumePopup";
import RejectPopup from "../Applications/RejectPopup";
import SharePopup from "../Applications/SharePopup";
import FilterTile from "../../common/FilterTile";
import ChangeView from "../../common/ChangeView";
import HirePopup from "../Applications/HirePopup";
import JobList from "./JobList";

interface AllJobsProps {
  isTesting?: boolean;
  selectedFiltersForRequest?: any;
}

const AllJobs: FC<AllJobsProps> = (props) => {
  const effectRan = useRef(false);

  const navigate = useNavigate();

  const jobFilter = useSelector((state: any) => state.jobFilter);
  const dispatch = useDispatch();

  const [filtersOpen, setFiltersOpen] = useState(false);

  const [selectedFilter, setSelectedFilter] = useState(
    ENUMS.JOB_FILTERS.JOB_TITLE
  );
  const [searchText, setSearchText] = useState("");

  const [filterDataLoading, setFilterDataLoading] = useState(false);

  const [jobTitleData, setJobTitleData] = useState(jobFilter.jobTitleData);
  const [jobLocationData, setJobLocationData] = useState(
    jobFilter.jobLocationData
  );
  const [employmentTypeData, setEmploymentTypeData] = useState(
    jobFilter.employmentTypeData
  );
  const [jobStatusData, setJobStatusData] = useState(jobFilter.jobStatusData);
  const [hiringManagerData, setHiringManagerData] = useState(
    jobFilter.hiringManagerData
  );
  const [noOfApplicationData, setNoOfApplicationData] = useState(
    jobFilter.noOfApplicationData
  );
  const [jobPostedData, setJobPostedData] = useState(jobFilter.jobPostedData);

  const [jobTitleFilters, setJobTitleFilters] = useState(
    jobFilter.jobTitleFilters
  );
  const [jobLocationFilters, setJobLocationFilters] = useState(
    jobFilter.jobLocationFilters
  );
  const [employmentTypeFilters, setEmploymentTypeFilters] = useState(
    jobFilter.employmentTypeFilters
  );
  const [jobStatusFilters, setJobStatusFilters] = useState(
    jobFilter.jobStatusFilters
  );
  const [hiringManagerFilters, setHiringManagerFilters] = useState(
    jobFilter.hiringManagerFilters
  );
  const [noOfApplicationFilters, setNoOfApplicationFilters] = useState(
    jobFilter.noOfApplicationFilters
  );
  const [jobPostedFilters, setJobPostedFilters] = useState(
    jobFilter.jobPostedFilters
  );

  const [jobListData, setJobListData] = useState([]);
  const [jobListLoading, setJobListLoading] = useState(false);
  const [updateDataAgain, setUpdateDataAgain] = useState(false);
  const [pagination, setPagination] = useState(jobFilter.pagination);
  const [totalJobCount, setTotalJobCount] = useState(0);
  const [showActiveJobs, setShowActiveJobs] = useState(
    jobFilter.showActiveJobs
  );
  const [sortModel, setSortModel] = useState<any>(jobFilter.sortModel);

  const [selectedFiltersForRequest, setSelectedFiltersForRequest] =
    useState<any>([]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [typeOfView, setTypeOfView] = useState<"list" | "tree">(
    jobFilter.typeOfView
  );

  const [hierarchyViewData, setHierarchyViewData] = useState<any>([]);

  const [shareOpen, setShareOpen] = useState(false);
  const [shareDetails, setShareDetails] = useState();

  const [resumeOpen, setResumeOpen] = useState(false);
  const [resumeLink, setResumeLink] = useState("");

  const [hireOpen, setHireOpen] = useState(false);
  const [rejectOpen, setRejectOpen] = useState(false);

  const [scheduleInterviewOpen, setScheduleInterviewOpen] = useState(false);
  const [availabilities, setAvailabilities] = useState([]);
  const [slotId, setSlotId] = useState<string[]>([]);
  const [candidateName, setCandidateName] = useState("");
  const [description, setDescription] = useState<string>("");
  const [candidateEmail, setCandidateEmail] = useState("");
  const [resumeVLNStatus, setResumeVLNStatus] = useState("");
  const [submissionId, setSubmissionId] = useState("");
  const [resumeDocOpen, setResumeDocOpen] = useState(false);
  const [resumeTxtOpen, setResumeTxtOpen] = useState(false);
  const [documentName, setDocumentName] = useState("");
  const [isIMGorPDF, setIsIMGorPDF] = useState(false);

  useEffect(() => {
    if (props.isTesting) {
      if (props.selectedFiltersForRequest) {
        setSelectedFiltersForRequest(props.selectedFiltersForRequest);
      }

      onResetAll();
      onApply();
      updateFilterKeys();
      onClearAllFilters();
      removeFilter(ENUMS.JOB_FILTERS.EMPLOYMENT_TYPE);
      removeFilter(ENUMS.JOB_FILTERS.HIRING_MANAGER);
      removeFilter(ENUMS.JOB_FILTERS.JOB_LOCATION);
      removeFilter(ENUMS.JOB_FILTERS.JOB_POSTED_DATE);
      removeFilter(ENUMS.JOB_FILTERS.JOB_TITLE);
      removeFilter(ENUMS.JOB_FILTERS.NO_OF_APPLICATION);
      removeFilter(ENUMS.JOB_FILTERS.STATUS);
      onShare({});
      onHire({});
      onReject({});
      onResumeOpen({}, true, "");
      handleGetJiraView({});
      onScheduleInterviewOpen(
        "Interview Requested",
        [
          {
            isActive: true,
          },
        ],
        "candidate name",
        "candidate email",
        "id"
      );
    }
  }, []);

  useEffect(() => {
    getJobList();
    updateFilterKeys();
    updatedRedux();
  }, [updateDataAgain, pagination, typeOfView]);

  useEffect(() => {
    if (effectRan.current === true) return;

    effectRan.current = true;

    return () => {
      onClearAllFilters();
    };
  }, [effectRan.current]);

  const getJobList = () => {
    setJobListLoading(true);

    const advanceSearch = {
      jobTitle: jobTitleFilters,
      location: jobLocationFilters,
      postingDate: jobPostedFilters,
      employmentType: employmentTypeFilters,
      jobStatus: jobStatusFilters,
      hiringManager: hiringManagerFilters,
      noOfApplications: noOfApplicationFilters,
    };

    let sortField = "";
    let sortOrder = "Descending";

    if (sortModel?.[0]?.sort === "asc") {
      sortOrder = "Ascending";
    }

    const isOnlyActive =
      jobStatusFilters.length === 1 && jobStatusFilters.includes("Active");

    if (sortModel?.[0]?.field === "referenceNumber") {
      sortField = "jobId";
    } else if (sortModel?.[0]?.field === "city") {
      sortField = "location";
    } else if (sortModel?.[0]?.field === "noOfApplications") {
      sortField = "noOfApplication";
    } else if (
      sortModel?.[0]?.field === "jobTitle" ||
      sortModel?.[0]?.field === "jobType" ||
      sortModel?.[0]?.field === "jobStatus" ||
      sortModel?.[0]?.field === "hiringManagerName" ||
      sortModel?.[0]?.field === "postingDate"
    ) {
      sortField = sortModel?.[0]?.field;
    }

    const payload = {
      pageSize: pagination.pageSize,
      pageIndex: pagination.pageIndex + 1,
      sortColumn: sortField,
      sortOrder: sortOrder,
      isSorted: true,
      advanceSearch: advanceSearch,
      showActiveJobs: isOnlyActive,
    };

    if (typeOfView === "list") {
      API.getJobs(payload)
        .then((response) => {
          if (response.entityList) {
            setJobListData(response.entityList);
          } else {
            setJobListData([]);
          }
          setJobListLoading(false);
          setTotalJobCount(Number(response.totalRecords));
        })
        .catch((e) => {
          setJobListLoading(false);
        });
    } else {
      handleGetJiraView(payload);
    }
  };

  const handleGetJiraView = (payload: any) => {
    API.getJiraView(payload, true)
      .then((response) => {
        const newEntityList = response.entityList.map(
          (item: any, index: number) => {
            let expanded = true;

            if (!item.submissions?.length) {
              expanded = false;
            }

            return {
              ...item,
              expanded: expanded,
            };
          }
        );
        setHierarchyViewData(newEntityList);
        setJobListLoading(false);
        setTotalJobCount(Number(response.totalRecords));
      })
      .catch((e) => {
        setJobListLoading(false);
      });
  };

  const updatedRedux = () => {
    const updatedState = {
      jobTitleData: jobTitleData,
      jobLocationData: jobLocationData,
      employmentTypeData: employmentTypeData,
      jobStatusData: jobStatusData,
      hiringManagerData: hiringManagerData,
      noOfApplicationData: noOfApplicationData,
      jobPostedData: jobPostedData,
      jobTitleFilters: jobTitleFilters,
      jobLocationFilters: jobLocationFilters,
      employmentTypeFilters: employmentTypeFilters,
      jobStatusFilters: jobStatusFilters,
      hiringManagerFilters: hiringManagerFilters,
      noOfApplicationFilters: noOfApplicationFilters,
      jobPostedFilters: jobPostedFilters,
      pagination: pagination,
      showActiveJobs: showActiveJobs,
      sortModel: sortModel,
      typeOfView: typeOfView,
    };

    dispatch({ type: ActionTypes.UPDATE_JOB_STATE, payload: updatedState });
  };

  const onResetAll = () => {
    setJobTitleFilters([]);
    setJobLocationFilters([]);
    setEmploymentTypeFilters([]);
    setJobStatusFilters([]);
    setHiringManagerFilters([]);
    setNoOfApplicationFilters([]);
    setJobPostedFilters([]);
  };

  const onApply = () => {
    setFiltersOpen(false);
    setUpdateDataAgain(!updateDataAgain);
    if (typeOfView === "tree") {
      setPagination({
        pageSize: pagination.pageSize,
        pageIndex: 0,
      });
    }
  };

  const removeFilter = (filter: string) => {
    if (filter === ENUMS.JOB_FILTERS.JOB_TITLE) {
      setJobTitleFilters([]);
    } else if (filter === ENUMS.JOB_FILTERS.JOB_LOCATION) {
      setJobLocationFilters([]);
    } else if (filter === ENUMS.JOB_FILTERS.JOB_POSTED_DATE) {
      setJobPostedFilters([]);
    } else if (filter === ENUMS.JOB_FILTERS.EMPLOYMENT_TYPE) {
      setEmploymentTypeFilters([]);
    } else if (filter === ENUMS.JOB_FILTERS.STATUS) {
      setJobStatusFilters([]);
    } else if (filter === ENUMS.JOB_FILTERS.HIRING_MANAGER) {
      setHiringManagerFilters([]);
    } else if (filter === ENUMS.JOB_FILTERS.NO_OF_APPLICATION) {
      setNoOfApplicationFilters([]);
    }
    setUpdateDataAgain(!updateDataAgain);
    if (typeOfView === "tree") {
      setPagination({
        pageSize: pagination.pageSize,
        pageIndex: 0,
      });
    }
  };

  const updateFilterKeys = () => {
    let selectedFiltersForRequestPass = [];

    if (jobTitleFilters.length > 0) {
      selectedFiltersForRequestPass.push(ENUMS.JOB_FILTERS.JOB_TITLE);
    }
    if (jobLocationFilters.length > 0) {
      selectedFiltersForRequestPass.push(ENUMS.JOB_FILTERS.JOB_LOCATION);
    }
    if (jobPostedFilters.length > 0) {
      selectedFiltersForRequestPass.push(ENUMS.JOB_FILTERS.JOB_POSTED_DATE);
    }
    if (employmentTypeFilters.length > 0) {
      selectedFiltersForRequestPass.push(ENUMS.JOB_FILTERS.EMPLOYMENT_TYPE);
    }
    if (jobStatusFilters.length > 0) {
      const isOnlyActive =
        jobStatusFilters.length === 1 && jobStatusFilters.includes("Active");

      setShowActiveJobs(isOnlyActive);

      selectedFiltersForRequestPass.push(ENUMS.JOB_FILTERS.STATUS);
    } else {
      setShowActiveJobs(false);
    }

    if (hiringManagerFilters.length > 0) {
      selectedFiltersForRequestPass.push(ENUMS.JOB_FILTERS.HIRING_MANAGER);
    }
    if (noOfApplicationFilters.length > 0) {
      selectedFiltersForRequestPass.push(ENUMS.JOB_FILTERS.NO_OF_APPLICATION);
    }

    if (selectedFiltersForRequestPass.length > 0) {
      setSelectedFiltersForRequest([...selectedFiltersForRequestPass]);
    } else {
      setSelectedFiltersForRequest([]);
    }
  };

  const onClearAllFilters = () => {
    onResetAll();
    setUpdateDataAgain(!updateDataAgain);
    if (typeOfView === "tree") {
      setPagination({
        pageSize: pagination.pageSize,
        pageIndex: 0,
      });
    }
  };

  const handleClick = (event: any) => {
    if (filtersOpen) {
      setAnchorEl(null);
      setFiltersOpen(false);
    } else {
      setAnchorEl(event.currentTarget);
      setFiltersOpen(true);
    }
  };

  const onShare = (row: any) => {
    setShareDetails(row);
    setShareOpen(true);
  };

  const onHire = (row: any) => {
    setShareDetails(row);
    setHireOpen(true);
  };

  const onReject = (row: any) => {
    setShareDetails(row);
    setRejectOpen(true);
  };

  const onResumeOpen = (link: any, isDoc: boolean, docName: string) => {
    if (isDoc) {
      setResumeDocOpen(true);
    } else {
      if (docName.includes("txt")) {
        setResumeTxtOpen(true);
      } else {
        let isIMGorPDFPass = Functions.Validation.isFileIMG(docName);
        setIsIMGorPDF(isIMGorPDFPass);
        setResumeOpen(true);
      }
    }
    setResumeLink(link);
    setDocumentName(docName);
  };

  const onScheduleInterviewOpen = (
    resumeVLNStatus: string,
    currentAvailabilities: any,
    candidateName: string,
    candidateEmail: string,
    id: string
  ) => {
    setCandidateName(candidateName);
    setResumeVLNStatus(resumeVLNStatus);
    setCandidateEmail(candidateEmail);
    setSubmissionId(id);
    if (
      resumeVLNStatus === "Interview Requested" ||
      resumeVLNStatus === "Interview Scheduled"
    ) {
      const activeAvailabilities = currentAvailabilities?.filter(
        (availability: any) => availability.isActive
      );
      const slotId = currentAvailabilities?.filter(
        (availability: any) => availability.id
      );

      if (activeAvailabilities?.length > 0) {
        setSlotId(slotId);
        setAvailabilities(activeAvailabilities?.[0]?.currentAvailabilities);
        setDescription(activeAvailabilities?.[0]?.description);
      }
    }

    setScheduleInterviewOpen(true);
  };

  return (
    <Box
      data-testid="jobs-page"
      sx={{
        height: "90vh",
        width: "100%",
        backgroundColor: Colors.LightWhite,
        overflowY: "auto",
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          pl: 4,
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: Colors.White,
          py: 1,
          position: "relative",
        }}
      >
        <Breadcrumbs
          sx={{
            pt: 0,
          }}
          path={[
            { path: Paths.APP + Paths.APPLICATIONS, name: "Home" },
            { name: "Jobs" },
          ]}
        />
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          pl: 4,
          alignItems: "center",
          justifyContent: "space-between",
          backgroundColor: Colors.White,
          py: 1,
          position: "relative",
        }}
      >
        <Box
          sx={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <FilterPopupJobs
            isExpanded={filtersOpen}
            setIsExpanded={setFiltersOpen}
            selectedFilter={selectedFilter}
            setSelectedFilter={setSelectedFilter}
            searchText={searchText}
            setSearchText={setSearchText}
            dataLoading={filterDataLoading}
            setDataLoading={setFilterDataLoading}
            jobTitleData={jobTitleData}
            setJobTitleData={setJobTitleData}
            jobLocationData={jobLocationData}
            setJobLocationData={setJobLocationData}
            employmentTypeData={employmentTypeData}
            setEmploymentTypeData={setEmploymentTypeData}
            jobStatusData={jobStatusData}
            setJobStatusData={setJobStatusData}
            hiringManagerData={hiringManagerData}
            setHiringManagerData={setHiringManagerData}
            noOfApplicationData={noOfApplicationData}
            setNoOfApplicationData={setNoOfApplicationData}
            jobPostedData={jobPostedData}
            setJobPostedData={setJobPostedData}
            jobTitleFilters={jobTitleFilters}
            setJobTitleFilters={setJobTitleFilters}
            jobLocationFilters={jobLocationFilters}
            setJobLocationFilters={setJobLocationFilters}
            employmentTypeFilters={employmentTypeFilters}
            setEmploymentTypeFilters={setEmploymentTypeFilters}
            jobStatusFilters={jobStatusFilters}
            setJobStatusFilters={setJobStatusFilters}
            hiringManagerFilters={hiringManagerFilters}
            setHiringManagerFilters={setHiringManagerFilters}
            noOfApplicationFilters={noOfApplicationFilters}
            setNoOfApplicationFilters={setNoOfApplicationFilters}
            jobPostedFilters={jobPostedFilters}
            setJobPostedFilters={setJobPostedFilters}
            onResetAll={onResetAll}
            onApply={onApply}
            anchorEl={anchorEl}
          />
          <FilterButton
            testId="filter-toggle-btn"
            onClick={(ev: any) => handleClick(ev)}
          />
          {selectedFiltersForRequest.map((item: any) => (
            <FilterTile
              key={item}
              onClick={() => removeFilter(item)}
              title={item}
            />
          ))}
          {selectedFiltersForRequest.length > 0 && (
            <ClearAllFiltersButton
              testId="clear-all-filters-btn"
              onClick={onClearAllFilters}
            />
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ActiveJobsSwitch
            tooltipTitle={showActiveJobs ? "View all jobs" : "View active jobs"}
            label="Active Jobs"
            testId="active-jobs-switch"
            switchProps={{
              checked: showActiveJobs,
              onChange: () => {
                setShowActiveJobs(!showActiveJobs);
                if (showActiveJobs) {
                  setJobStatusFilters([]);
                } else {
                  setJobStatusFilters(["Active"]);
                }
                setUpdateDataAgain(!updateDataAgain);
                setPagination({
                  pageSize: pagination.pageSize,
                  pageIndex: 0,
                });
              },
            }}
          />
          <ChangeView
            sx={{
              mr: 5,
            }}
            typeOfView={typeOfView}
            onToggle={(ev: any) => {
              setTypeOfView(ev);
            }}
          />
          <ButtonFilled
            title="Create Job"
            sx={{
              mr: 5,
            }}
            onClick={() => {
              navigate(Paths.JOB + Paths.CREATE_JOB);
            }}
          />
        </Box>
      </Box>
      {typeOfView === "list" && (
        <JobList
          jobListData={jobListData}
          jobListLoading={jobListLoading}
          setJobListLoading={setJobListLoading}
          pageIndex={pagination.pageIndex}
          pageSize={pagination.pageSize}
          totalEntries={totalJobCount}
          setPagination={setPagination}
          sortModel={sortModel}
          setSortModel={setSortModel}
          updateDataAgain={updateDataAgain}
          setUpdateDataAgain={setUpdateDataAgain}
        />
      )}
      {typeOfView === "tree" && (
        <ApplicationHierarchy
          hierarchyViewData={hierarchyViewData}
          setHierarchyViewData={setHierarchyViewData}
          tableLoading={jobListLoading}
          pageIndex={pagination.pageIndex}
          pageSize={pagination.pageSize}
          setPagination={setPagination}
          totalEntries={totalJobCount}
          sortModel={sortModel}
          setUpdateDataAgain={setUpdateDataAgain}
          updateDataAgain={updateDataAgain}
          setSortModel={setSortModel}
          onShare={onShare}
          onHire={onHire}
          onReject={onReject}
          onResumeOpen={onResumeOpen}
          onScheduleInterviewOpen={onScheduleInterviewOpen}
        />
      )}
      <HirePopup
        open={hireOpen}
        applicationDetails={shareDetails}
        setOpen={setHireOpen}
        updateDataAgain={updateDataAgain}
        setUpdateDataAgain={setUpdateDataAgain}
      />
      <RejectPopup
        open={rejectOpen}
        applicationDetails={shareDetails}
        updateDataAgain={updateDataAgain}
        setOpen={setRejectOpen}
        setUpdateDataAgain={setUpdateDataAgain}
      />
      <SharePopup
        open={shareOpen}
        shareDetails={shareDetails}
        setOpen={setShareOpen}
      />
      <ResumePopup
        open={resumeOpen}
        resumeLink={resumeLink}
        setOpen={setResumeOpen}
        isIMGorPDF={isIMGorPDF}
      />
      <ResumeDocPopup
        open={resumeDocOpen}
        setOpen={setResumeDocOpen}
        documentLink={resumeLink}
        documentName={documentName}
      />
      <ResumeTxtPopup
        open={resumeTxtOpen}
        setOpen={setResumeTxtOpen}
        documentLink={resumeLink}
      />
      <ModalForProposeTimeSlot
        scheduleInterviewOpen={scheduleInterviewOpen}
        setScheduleInterviewOpen={setScheduleInterviewOpen}
        setAvailabilities={setAvailabilities}
        availabilities={availabilities}
        candidateName={candidateName}
        candidateEmail={candidateEmail}
        slotId={slotId}
        setSlotId={setSlotId}
        description={description}
        resumeVLNStatus={resumeVLNStatus}
        setDescription={setDescription}
        id={submissionId}
        updateDataAgain={updateDataAgain}
        setUpdateDataAgain={setUpdateDataAgain}
      />
    </Box>
  );
};

export default AllJobs;
