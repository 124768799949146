// React Imports
import { FC, useEffect } from "react";

// UI Imports
import { Box, Modal } from "@mui/material";

// Functional Imports

// Component Imports

interface ResumePopupProps {
  open: boolean;
  setOpen: any;
  resumeLink?: any;
  isIMGorPDF?: boolean;

  isTesting?: boolean;
}

const ResumePopup: FC<ResumePopupProps> = (props) => {
  useEffect(() => {
    if (props.isTesting) {
      handleClose();
    }
  }, []);

  const handleClose = () => {
    props.setOpen(false);
  };

  return (
    <Modal
      open={props.open}
      onClose={handleClose}
      sx={{
        border: "none",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box data-testid="resume-popup">
        {!props.isIMGorPDF && (
          <Box
            sx={{
              border: "none",
              width: "60vw",
              height: "85vh",
            }}
          >
            <iframe
              src={props.resumeLink}
              style={{
                width: "100%",
                height: "100%",
              }}
            ></iframe>
          </Box>
        )}
        {props.isIMGorPDF && (
          <Box
            component={"img"}
            src={props.resumeLink}
            sx={{
              objectFit: "contain",
              border: "none",
              width: "60vw",
              height: "85vh",
            }}
          />
        )}
      </Box>
    </Modal>
  );
};

export default ResumePopup;
