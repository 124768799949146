// React Imports
import { FC } from "react";

// UI Imports
import { Box } from "@mui/material";

// Functional Imports
import Functions from "../../utils/Functions";
import CONSTANTS from "../../utils/Constants";
import Colors from "../../utils/Colors";
import ENUMS from "../../utils/Enums";

// Component Imports
import LabelInputReadOnly from "../../common/LabelInputReadOnly";
import CStatusChip from "../../common/CStatusChip";
import Text from "../../common/Text";

interface ProjectDetailTabProps {
  projectDetailData?: any;
}

const ProjectDetailTab: FC<ProjectDetailTabProps> = (props) => {
  return (
    <Box
      sx={{
        backgroundColor: Colors.White,
        width: "95%",
        mx: 4,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          px: 3,
          pt: 3,
        }}
      >
        <LabelInputReadOnly
          label="Project ID"
          value={props.projectDetailData?.projectId}
        />
        <LabelInputReadOnly
          label="Bill rate"
          value={Functions.Sonar.returnStringWithDollarOrNA(
            props.projectDetailData?.billRate
          )}
        />
        <LabelInputReadOnly
          label="OT Bill rate"
          value={Functions.Sonar.returnStringWithDollarOrNA(
            props.projectDetailData?.otBillRate
          )}
        />
        <LabelInputReadOnly
          label="Account Manager"
          value={props.projectDetailData?.accountManager}
        />
      </Box>
      <Box
        sx={{
          width:
            props.projectDetailData?.projectStatus ===
            ENUMS.JOB_STATUS_TYPES.ACTIVE
              ? 115
              : 125,
          px: 3,
          pb: 3,
        }}
      >
        <Text>Status</Text>        
        <CStatusChip
          type={props.projectDetailData?.projectStatus}
          options={CONSTANTS.STATUS_CHIP.ACTIVE_INACTIVE_REVIEW}
          style={{
            marginTop: 8,
          }}
        />
      </Box>
    </Box>
  );
};

export default ProjectDetailTab;
