// React Imports
import { CSSProperties, FC } from "react";

// UI Imports

// Functional Imports

// Component Imports
import Text from "../Text";

interface CStatusChipProps {
  type?: string;
  options?: any[];
  style?: CSSProperties;

  testId?: string;
}

const CStatusChip: FC<CStatusChipProps> = (props) => {
  let backgroundColor;
  let borderColor;
  let textColor;

  props.options?.map((item) => {
    if (item.type === props.type) {
      backgroundColor = item.backgroundColor;
      borderColor = item.borderColor;
      textColor = item.textColor;
    }
  });

  return (
    <Text
      testId={props.testId}
      style={{
        backgroundColor: backgroundColor,
        border: "1px solid " + borderColor,
        padding: "2px 12px 2px 12px",
        borderRadius: 16,
        ...props.style,
      }}
      color={textColor}
    >
      {props.type}
    </Text>
  );
};

export default CStatusChip;
