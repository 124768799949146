// React Imports
import { FC } from "react";

// UI Imports
import { Box, IconButton } from "@mui/material";
import { FaChevronDown } from "react-icons/fa";
import { FaChevronUp } from "react-icons/fa";

// Functional Imports
import Colors from "../../utils/Colors";

// Component Imports
import Text from "../../common/Text";

interface TimesheetTitleProps {
  isExpanded?: boolean;
  weekStart?: string;
  weekEnd?: string;
  totalHours?: string;
  onClick?: any;
}

const TimesheetTitle: FC<TimesheetTitleProps> = (props) => {
  return (
    <Box
      sx={{
        height: 50,
        width: "100%",
        border: "1px solid " + Colors.Grey21,
        borderBottom: props.isExpanded ? 0 : null,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: 2,
          pl: 2,
        }}
      >
        <IconButton onClick={props.onClick}>
          {!props.isExpanded && <FaChevronDown size={18} />}
          {props.isExpanded && <FaChevronUp size={18} />}
        </IconButton>

        <Text fontSize={16} fontWeight={500} color={Colors.Blue7}>
          {props.weekStart + " - " + props.weekEnd}
        </Text>

        <Text fontSize={16} fontWeight={500} color={Colors.Blue7}>
          |
        </Text>

        <Text fontSize={16} color={Colors.Grey22}>
          Total hours: {props.totalHours}
        </Text>
      </Box>
    </Box>
  );
};

export default TimesheetTitle;
