const Colors = {
  // B/W
  Black: "#000000",
  Black1: "#353535",
  White: "#FFFFFF",
  LightWhite: "#F9FAFC",

  // Grey
  Grey5: "#D9D9D9",
  Grey9: "#696969",
  Grey1: "#E3E3E3",
  Grey2: "#DFDFE9",
  Grey3: "#69696D",
  Grey4: "#E5E5E6",
  Grey6: "#676767",
  Grey7: "#EEEEEE",
  Grey8: "#ECECEC",
  Grey10: "#DFDFE9",
  Grey11: "#F4F4F4",
  Grey12: "#EAEAEA",
  Grey13: "#6f717230",
  Grey14: "#D1D1D1",
  Grey15: "#F8F8F8",
  Grey16: "#DEDEDE",
  Grey17: "#E6E6E6",
  Grey18: "#F1F1F1",
  Grey19: "#CCC",
  Grey20: "#E2E2E2",
  Grey21: "#E9E9E9",
  Grey22: "#727272",
  Grey23: "#D5D5D5",
  Grey24: "#DDDDDD",

  // Colors

  // Blue
  LightBlue1: "#E8EEFF",
  LightBlue2: "#DAEBFF",
  LightBlue3: "#73CDFA",
  LightBlue4: "#DCF4FF",
  LightBlue5: "#F7F8FF",
  LightBlue6: "#F4F6FF",
  LightBlue7: "#EAEBF4",
  LightBlue8: "#4A57A31F",
  LightBlue9: "#DBE3EE",
  Blue7: "#4A57A3",
  Blue1: "#556EFD",
  Blue2: "#AAB0CE",
  Blue3: "#517AFC",
  Blue4: "#34A9C6",
  Blue5: "#0050EF",
  DarkBlue: "#195776",
  DarkBlue2: "#104682",
  DarkBlue3: "#8390DA",
  DarkBlue4: "#3182CE",

  // Red
  Red: "#F81919",
  Red2: "#FF4B42",
  Red3: "#D5130A",
  LightRed: "#FFE3E1",

  // Green
  Green: "#31AF4C",
  Green2: "#4FD465",
  Green3: "#40AD12",
  Green4: "#0F621D",
  LightGreen: "#E3FFE8",
  LightGreen2: "#F4FFEF",

  // Orange
  Orange: "#FF9D28",
  Orange2: "#F2B328",
  Orange3: "#FA8B2A",
  LightOrange: "#FFEED8",

  // Brown
  Brown: "#945810",

  // Purple
  Purple: "#A359EC",

  // Voilet
  Voilet: "#D63B74",
};

export default Colors;
