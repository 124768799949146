// React Imports
import { FC, useEffect, useState } from "react";

// UI Imports
import { Box } from "@mui/material";

// Functional Imports
import { Link, useParams, useSearchParams } from "react-router-dom";
import API from "../../api/API";
import Paths from "../../routes/Paths";
import dayjs from "dayjs";
import ENUMS from "../../utils/Enums";
import Colors from "../../utils/Colors";
import Functions from "../../utils/Functions";

// Component Imports
import RequestSubmissions from "../../common/RequestSubmissions";
import WorkerDetailTab from "./WorkerDetailTab";
import AttributeValue from "../../common/AttributeValue";
import EndProjectPopup from "../../common/EndProjectPopup";
import DocumentsList from "../../common/DocumentsList";
import ButtonFilled from "../../common/ButtonFilled";
import TabSelector from "../../common/TabSelector";
import Breadcrumbs from "../../common/Breadcrumbs";
import Timesheet from "../../common/Timesheet";
import Loader from "../../common/Loader";
import Text from "../../common/Text";

interface WorkerDetailsProps {
  isTesting?: boolean;
}

const WorkerDetails: FC<WorkerDetailsProps> = (props) => {
  const { id } = useParams();

  const [searchParams] = useSearchParams();

  const tab = searchParams.get("tab");
  const date = searchParams.get("date");

  const [workerDetailData, setWorkerDetailData] =
    useState(workerDetailDataInit);
  const [dataLoading, setDataLoading] = useState(false);
  const [tabSelected, setTabSelected] = useState(
    ENUMS.WORKER_TAB_OPTIONS.WORKER_DETAILS
  );
  const [documentListData, setDocumentListData] = useState([]);
  const [documentLoading, setDocumentLoading] = useState(false);

  const [workerDetailId, setWorkerDetailId] = useState("");
  const [month, setMonth] = useState(dayjs(new Date()));
  const [weeklyData, setWeeklyData] = useState<any>([]);
  const [timesheetLoading, setTimesheetLoading] = useState(false);
  const [maxMonth, setMaxMonth] = useState(dayjs(new Date()));
  const [endProjectPopupOpen, setEndProjectPopupOpen] = useState(false);
  const [endProjectPopupProjectId, setEndProjectPopupProjectId] =
    useState<any>("");
  const [endProjectPopupWorkerName, setEndProjectPopupWorkerName] =
    useState("");
  const [endProjectPopupProjectName, setEndProjectPopupProjectName] =
    useState("");
  const [endProjectPopupStartDate, setEndProjectPopupStartDate] =
    useState<any>("");
  const [isNotEndProjectRequestSubmitted, setIsNotEndProjectRequestSubmitted] =
    useState(false);

  useEffect(() => {
    if (tab === "timesheet") {
      setTabSelected(ENUMS.WORKER_TAB_OPTIONS.TIMESHEET);
    }

    getWorkerDetails();
  }, [id]);

  useEffect(() => {
    if (!!workerDetailId) {
      getTimesheets(workerDetailId, month);
    }
  }, [month]);

  useEffect(() => {
    if (props.isTesting) {
      handleEndProject();
    }
  }, []);

  const getWorkerDetails = () => {
    setDataLoading(true);
    API.getWorkerDetailsByID(String(id))
      .then((response) => {
        setDataLoading(false);
        setWorkerDetailData(response.entity);
        getDocuments(String(response.entity?.jobResumeId));
        setWorkerDetailId(response.entity?.projectDetailId);
        setIsNotEndProjectRequestSubmitted(
          !Functions.Validation.checkEndProjectRequestSubmitted(
            response.entity?.requestSubmissions
          )
        );

        let monthPass = month;

        if (dayjs(date).isValid()) {
          monthPass = dayjs(date);
          setMonth(monthPass);
        } else if (dayjs(response.entity?.startDate).isAfter(new Date())) {
          setMonth(dayjs(response.entity?.startDate));
          setMaxMonth(dayjs(response.entity?.startDate));
        }

        getTimesheets(response.entity?.projectDetailId, monthPass);
      })
      .catch((e) => {
        setDataLoading(false);
      });
  };

  const getTimesheets = (workerDetailId: string, month: any) => {
    let payload = {
      ProjectDetailId: workerDetailId,
      StartDate: month.startOf("month").format(),
      EndDate: month.endOf("month").format(),
    };

    setTimesheetLoading(true);
    API.getWorkerTimesheet(payload)
      .then((response) => {
        setTimesheetLoading(false);
        setWeeklyData(
          Functions.ValueRetrieve.processDatesWeekly(response.entityList)
        );
      })
      .catch((e) => {
        setTimesheetLoading(false);
      });
  };

  const getDocuments = (jobResumeId: string) => {
    setDocumentLoading(true);
    API.getDocumentsByJobResumeId(jobResumeId)
      .then((response) => {
        setDocumentLoading(false);
        setDocumentListData(response.entity?.documentDetails);
      })
      .catch((e) => {
        setDocumentLoading(false);
      });
  };

  const handleEndProject = () => {
    setEndProjectPopupOpen(true);
    setEndProjectPopupProjectId(id);
    setEndProjectPopupWorkerName(workerDetailData.employeeName);
    setEndProjectPopupProjectName(workerDetailData.projectName);
    setEndProjectPopupStartDate(workerDetailData.startDate);
  };

  return (
    <Box
      data-testid="worker-details-page"
      sx={{
        height: "90vh",
        width: "100%",
        backgroundColor: Colors.LightWhite,
        overflowY: "scroll",
      }}
    >
      {dataLoading && <Loader />}
      <Box
        sx={{
          width: "100%",
          display: "flex",
          pl: 4,
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: Colors.White,
          py: 1,
          position: "relative",
        }}
      >
        <Breadcrumbs
          sx={{
            pt: 1,
            pb: 1,
          }}
          path={[
            { path: Paths.DASHBOARD, name: "Home" },
            { path: Paths.WORKERS, name: "Workers" },
            { name: workerDetailData.projectId },
          ]}
        />
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mt: 1.5,
        }}
      >
        <Text
          fontSize={20}
          fontWeight={600}
          style={{
            marginTop: 16,
            marginLeft: 32,
          }}
        >
          Details
        </Text>
        <Box
          sx={{
            gap: 2,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mr: 5,
          }}
        >
          {isNotEndProjectRequestSubmitted && (
            <ButtonFilled onClick={handleEndProject} title="End Project" />
          )}
        </Box>
      </Box>
      <Box
        sx={{
          backgroundColor: Colors.White,
          height: 75,
          width: "95%",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          mx: 4,
          mt: 2,
        }}
      >
        <AttributeValue
          label="Worker name"
          value={workerDetailData.employeeName}
        />
        <Box
          sx={{
            pl: 4,
            width: "18%",
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <Text fontWeight={400} fontSize={14}>
            Project Name
          </Text>
          <Link to={Paths.PROJECT + "/" + id}>
            <Text color={Colors.Blue5} fontWeight={700} fontSize={14}>
              {workerDetailData.projectName}
            </Text>
          </Link>
        </Box>

        <AttributeValue label="Project ID" value={workerDetailData.projectId} />
        <AttributeValue
          label="Start Date"
          value={Functions.DateFunctions.formatDateObjectOrNA(
            workerDetailData.startDate
          )}
        />
        <AttributeValue
          label="End Date"
          value={Functions.DateFunctions.formatDateObjectOrNA(
            workerDetailData.endDate,
            "MM/DD/YYYY"
          )}
        />
      </Box>
      <TabSelector
        options={
          workerDetailData.requestSubmissions?.length > 0
            ? [
                ENUMS.WORKER_TAB_OPTIONS.WORKER_DETAILS,
                ENUMS.WORKER_TAB_OPTIONS.TIMESHEET,
                ENUMS.WORKER_TAB_OPTIONS.DOCUMENTS,
                ENUMS.WORKER_TAB_OPTIONS.REQUEST_SUBMISSIONS,
              ]
            : [
                ENUMS.WORKER_TAB_OPTIONS.WORKER_DETAILS,
                ENUMS.WORKER_TAB_OPTIONS.TIMESHEET,
                ENUMS.WORKER_TAB_OPTIONS.DOCUMENTS,
              ]
        }
        selectedOption={tabSelected}
        setSelectedOption={setTabSelected}
      />
      {tabSelected === ENUMS.WORKER_TAB_OPTIONS.WORKER_DETAILS && (
        <WorkerDetailTab workerDetailData={workerDetailData} />
      )}
      {tabSelected === ENUMS.WORKER_TAB_OPTIONS.TIMESHEET && (
        <Timesheet
          month={month}
          setMonth={setMonth}
          weeklyData={weeklyData}
          setWeeklyData={setWeeklyData}
          dataLoading={timesheetLoading}
          setDataLoading={setTimesheetLoading}
          projectStartDate={workerDetailData.startDate}
          maxMonth={maxMonth}
        />
      )}
      {tabSelected === ENUMS.WORKER_TAB_OPTIONS.DOCUMENTS && (
        <DocumentsList
          tableData={documentListData}
          tableLoading={documentLoading}
          sx={{
            width: "95%",
          }}
        />
      )}
      {tabSelected === ENUMS.WORKER_TAB_OPTIONS.REQUEST_SUBMISSIONS && (
        <RequestSubmissions
          tableData={workerDetailData.requestSubmissions}
          tableLoading={dataLoading}
        />
      )}
      <EndProjectPopup
        isWorker
        open={endProjectPopupOpen}
        setOpen={setEndProjectPopupOpen}
        setUpdateDataAgain={() => getWorkerDetails()}
        projectId={endProjectPopupProjectId}
        workerName={endProjectPopupWorkerName}
        projectName={endProjectPopupProjectName}
        startDate={endProjectPopupStartDate}
      />
    </Box>
  );
};

export default WorkerDetails;

const workerDetailDataInit = {
  projectDetailId: "",
  projectId: "",
  projectName: "",
  employeeName: "",
  startDate: null,
  endDate: null,
  workerStatus: "",
  billRate: null,
  otBillRate: null,
  accountManager: "",
  requestSubmissions: [],
};
